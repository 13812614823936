<template>
	<el-container class="login-page container">
		<el-main class="" v-loading="loading" :element-loading-text="type==1 ? '登录中...' : '注册中...'">
			<el-row>
				<el-col :span="12" :offset="6" :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
					<div class="logo-box">
						<div class="logo-header">
							<img class="logo" src="../assets/logo.jpg" />
							<!-- <div>比农出品</div> -->
							<h4>{{ type == 1 ? '欢迎登录' : '欢迎注册' }}</h4>
						</div>
						<div class="logo-main">
							<div class="login-type-box" v-if="type==1">
								<div class="login-item" v-for="(item,index) in loginItem" @click="changeLogin(index)"
									:class="currentLoginIndex==index ? 'login-item-active' : ''">{{item.name}}</div>
							</div>
							<form class="form-horizontal" role="form">
								<div class="form-group">
									<label for="id" class="col-sm-3 control-label">账号</label>
									<div class="col-sm-9">
										<input type="number" class="form-control" id="id" v-model="param.mobile"
											placeholder="请输入手机号">
									</div>
								</div>
								<div class="form-group" v-if="!loginByPass">
									<label for="password" class="col-sm-3 control-label">验证码</label>
									<div class="col-sm-9 code-box">
										<input type="text" class="form-control code-input" v-model="param.code"
											id="password" placeholder="请输入验证码">
										<div class="receive-code" @click="getSmsCode">
											<span v-if="!smsCodeBtnDisabled">获取验证码</span>
											<span v-else class="sms-code-resend">{{ `重新发送 (${codeSeconds})` }}</span>
										</div>
									</div>
								</div>
								<div class="form-group" v-if="loginByPass || type==2">
									<label for="id" class="col-sm-3 control-label">密码</label>
									<div class="col-sm-9">
										<input type="password" class="form-control" id="id" v-model="param.password"
											placeholder="请输入密码">
									</div>
								</div>
								<div class="form-group" v-if="(loginByPass&&type!=1) || type==2">
									<label for="id" class="col-sm-3 control-label">确认密码</label>
									<div class="col-sm-9">
										<input type="password" class="form-control" id="id" v-model="param.password_repetition"
											placeholder="请输入确认密码">
									</div>
								</div>
								<div class="form-group  btn-box">
									<div class="col-sm-offset-2">
										<button type="button" class="btn btn-success" @click="toLogin">
											{{ type == 1 ? '登录' : '注册' }}
										</button>
									</div>
								</div>
								<div class="form-group register-btn">
									<a href="#" v-if="type == 1" @click="changeType(2)">还没有账号？去注册</a>
									<a href="#" v-else @click="changeType(1)">去登录</a>
								</div>
							</form>
						</div>
					</div>
				</el-col>
			</el-row>
		</el-main>
	</el-container>
</template>
<script>
	import {
		Login
	} from '@/api/login'
	var login = new Login();
	import {
		Public
	} from '../api/public.js'
	var publicModel = new Public();
	export default {
		data() {
			return {
				param: {
					mobile: '',
					code: '',
					password: '',
					password_repetition:''
				},
				loginByPass: false, //账号密码登录
				currentPage: '',
				type: 1, //1登录 2注册
				smsCodeBtnDisabled: false,
				codeSeconds: 0, // 验证码发送时间间隔
				currentLoginIndex:0,
				loginItem:[
					{
						name:'短信验证码登录',
						type:'sms'
					},
					{
						name:'账号密码登录',
						type:'pwd'
					}
				],
				loading:false
			}
		},
		created() {
			this.type = this.$route&&this.$route.query&&this.$route.query.type;
			var currentPage = localStorage.getItem('currentPage');
			this.currentPage = JSON.parse(currentPage);
			if (this.$route && this.$route.path && this.$route.path == '/login') {
				this.$mStore.commit('setIsLoginPage', true)
			} else {
				this.$mStore.commit('setIsLoginPage', false)
			}
		},
		methods: {
			toLogin() {
				var param = this.param;
				var that = this;
				this.loading=true;
				//登录
				if(this.type==1){
					if(!this.loginByPass){
						login.smsLogin(param).then(res => {
							that.loading=false;
							if(res.status==1){
								that.$mStore.state.hasLogin = true;
								//登录成功后请求购物车接口
								this.$store.dispatch('GetCartNum').then(() => {
														
								}).catch(() => {
														
								});
								var access_token = res.access_token;
								// 存储数据
								localStorage.setItem('access_token', access_token);
								var url = this.currentPage && this.currentPage.path != '/login' ? this.currentPage.path : '/indexMain';
								var query = this.currentPage && this.currentPage.query ?  this.currentPage.query : {};
								setTimeout(function() {
									that.$router.push({
										path: url,
										query: query
									}).catch(() => {
														
									});
								}, 2000);
								this.$message.success('登录成功！');
							}else{
								this.$message.error(res.msg ? res.msg : '登录失败！');
							}
						}).catch(() => {
							that.loading=false;
						});
					}else{
						// var pwdparam={
						// 	password:this.param.password,
						// 	username:this.param.mobile
						// };
						publicModel.loginBypdw(param).then(res => {
							that.loading=false;
							that.$mStore.state.hasLogin = true;
							var access_token = res.items.access_token;
							//登录成功后请求购物车接口
							this.$store.dispatch('GetCartNum').then(() => {
													
							}).catch(() => {
													
							});
							// 存储数据
							localStorage.setItem('access_token', access_token);
							var url = this.currentPage && this.currentPage.path != '/login' ? this.currentPage.path : '/indexMain';
							var query = this.currentPage && this.currentPage.query ?  this.currentPage.query : {};
							setTimeout(function() {
								that.$router.push({
									path: url,
									query: query
								}).catch(() => {
						
								});
							}, 2000);
							this.$message.success('登录成功！');
						}).catch(() => {
							that.loading=false;
						});
					}
				}else{
					//注册
					var reg_param=param;
					reg_param.group='pc';
					if(reg_param.password!==reg_param.password_repetition){
						that.$message.error('密码与确认密码不一致，请重新填写~');
						that.loading=false;
						return false;
					}
					publicModel.register(reg_param).then(res => {
						that.loading=false;
						that.smsCodeBtnDisabled=false;
						that.codeSeconds=0;
						setTimeout(function() {
							that.type=1;
							that.changeLogin(1);
							that.param={
								mobile: '',
								code: '',
								password: '',
								password_repetition:''
							};
						}, 2000);
						this.$message.success('注册成功！使用账号密码登录吧~');
					}).catch(() => {
						that.loading=false;
					});
				}
			},
			changeType(type) {
				this.type = type;
				this.smsCodeBtnDisabled=false;
				this.codeSeconds=0;
				this.param= {
					mobile: '',
					code: '',
					password: '',
					password_repetition:''
				};
				if(type==2){
					this.loginByPass=false;
				}
			},
			//
			changeLogin(index){
				this.currentLoginIndex=index;
				if(index===0){
					this.loginByPass=false;
				}else{
					this.loginByPass=true;
				}
			},
			// 发送验证码并进入倒计时
			async getSmsCode() {
				var that = this;
				if (!this.param.mobile) {
					this.$message.error('请填写手机号');
					return false;
				}
				//获取短信验证码
				var param = {
					mobile: this.param.mobile,
					scene: 1
				};
				publicModel.sms(param).then(data => {
					if (data.status == 1) {
						that.$message.success('验证码已发送，请注意查看！')
						that.smsCodeBtnDisabled = true;
						//倒计时
						that.handleSmsCodeTime(59);
					} else {
						that.$message.error(data.msg ? data.msg : '短信发送失败');
					}
				}).catch(()=>{
					
				});
			},
			handleSmsCodeTime(time='') {
				let timer = setInterval(() => {
					if (time === 0) {
						clearInterval(timer);
						this.smsCodeBtnDisabled = false;
					} else {
						this.codeSeconds = time;
						this.smsCodeBtnDisabled = true;
						time--
					}
				}, 1000);
			}
		}
	}
</script>
<style>
	@import '../styles/login.css';
	.login-type-box{
		    display: flex;
		    justify-content: space-around;
		    border: 1px solid #eee;
		    width: 70%;
		    margin-left: 15%;
		    height: 40px;
			line-height: 40px;
		    border-radius: 4px;
			margin-bottom: 20px;
	}
	.border-right{
		border-right: 1px solid #eee;
	}
	.login-item{
		text-align: center;
		width: 50%;
		cursor: pointer;
	}
	.login-item-active{
		background-color: #ddf4ed;
		color: #20b483;
		font-weight: bold;
		border: none;
	}
</style>