<template>
	<div class='confirm-box'>
		<el-row :gutter="24">
			<el-col :xs="24" :sm="24" :md='24' :lg="24" :xl="24">
				<div class="list">
					<div class="label-txt">
						<div>收货人信息</div>
						<div class="add-address">
							<el-popover placement="right" width="400" trigger="click" @show="openPopover"
  @hide="closePopover">
								<el-form label-width="80px" v-if="showAddress">
									<div class="add-address-title">新增收货地址</div>
									<el-form-item label="姓名:">
										<el-input v-model="addAddressParam.userName"></el-input>
									</el-form-item>
									<el-form-item label="手机">
										<el-input v-model="addAddressParam.telNumber"></el-input>
									</el-form-item>
									<el-form-item label="省市区:">
										<el-select class="ssq-input" v-model="addAddressParam.provinceName"
											@change="changeProvince">
											<el-option v-for="(item, index) in province" :key="item.id"
												:label="item.name" :value="index">
											</el-option>
										</el-select>
										<el-select class="ssq-input" v-model="addAddressParam.cityName"
											@change="changeCity">
											<el-option v-for="(item, index) in city" :key="item.id" :label="item.name"
												:value="index">
											</el-option>
										</el-select>
										<el-select class="ssq-input" v-model="addAddressParam.countyName"
											@change="changeArea">
											<el-option v-for="(item, index) in area" :key="item.id" :label="item.name"
												:value="index">
											</el-option>
										</el-select>
									</el-form-item>
									<el-form-item class="address-detail" label="详细地址">
										<el-input v-model="addAddressParam.detailInfo"></el-input>
									</el-form-item>
									<el-form-item class="" label="是否默认">
										<el-radio v-model="radio" label="1">是</el-radio>
										<el-radio v-model="radio" label="0">否</el-radio>
									</el-form-item>
									<button type="button" class="btn btn-success add-save-btn"
										@click="createAddress">保存</button>
								</el-form>
								<el-button slot="reference" @click="showAddress = !showAddress">
									<i class="el-icon-plus"></i>新增收货地址
								</el-button>
							</el-popover>
							<!-- <i class="el-icon-plus"></i>新增收货地址 -->
						</div>
					</div>
					<el-scrollbar>
						<ul>
							<li v-for="(item, index) in addressList" class="address-item" v-if="addressList.length>0"
								:class="activeIndex === index ? 'active-address-item' : ''"
								@click="selectAddress(index)">
								<span v-if="activeIndex === index">
									<i class="el-icon-check icon-color"></i>
								</span>
								<span>{{ item.userName || '' }}</span>
								<span>{{ item.provinceName || '' }}{{ item.cityName || '' }}{{ item.countyName || '' }}
									{{ item.detailInfo || '' }}</span>
							</li>
						</ul>
					</el-scrollbar>
				</div>
				<div class="list">
					<div class="label-txt">支付方式</div>
					<ul class="pay-list">
						<li v-for="(item, index) in payList" class="address-item pay-item"
							:class="payIndex === index ? 'active-address-item' : ''" @click="selectpay(index)">
							<span v-if="payIndex === index">
								<i class="el-icon-check icon-color"></i>
							</span>
							<span>{{ item.name }}</span>
						</li>
					</ul>
				</div>
				<div class="list">
					<div class="label-txt">确认订单信息</div>
					<div class="">
						<div class="table table-bordered">
							<el-row class="product-top">
								<!-- <div class="product-top"> -->
								<el-col :xs='12' :sm='12' :md='12' :lg='12' :xl='12'>商品信息</el-col>
								<el-col :xs='4' :sm='4' :md='4' :lg='4' :xl='4'>价格</el-col>
								<el-col :xs='4' :sm='4' :md='4' :lg='4' :xl='4'>数量</el-col>
								<el-col :xs='4' :sm='4' :md='4' :lg='4' :xl='4'>小计</el-col>
								<!-- </div> -->
							</el-row>
							<el-row v-for="(shopItem, index) in orderTOPayData">
								<div v-for="(item, index) in shopItem.data" class="product-item">
									<div class="product-item-top">
										<el-col :xs='12' :sm='12' :md='12' :lg='12' :xl='12'>
											<div class="product-name-box">
												<div class="product-img">
													<img :src="item.skus.thumbImg" v-if="item.skus.thumbImg" />
													<img src="../assets/640_05.jpg" v-else />
												</div>
												<div class="product-name">
													<div class="product-name-txt">{{ item.product_name }}</div>
													<div class="attr-color" v-if="item.sku_values">{{ item.sku_values }}
													</div>
												</div>
											</div>
										</el-col>
										<el-col :xs='4' :sm='4' :md='4' :lg='4' :xl='4' class="product-price">
											{{ item.sale_price_real }}
										</el-col>
										<el-col :xs='4' :sm='4' :md='4' :lg='4' :xl='4' class="product-num-box">
											{{item.num }}
										</el-col>
										<el-col :xs='4' :sm='4' :md='4' :lg='4' :xl='4' class="everyone-price">
											{{ (parseFloat(item.sale_price_real * item.num)).toFixed(2) }}
										</el-col>
									</div>
									<!-- <div class="product-item-bottom">
                                        <div class="bottom-item">
                                            <div>给卖家留言：<textarea></textarea></div>
                                            <div>运送方式：普通配送 免邮</div>
                                        </div>
                                        <div class="bottom-item">
                                            <div>运费险：卖家赠送，退换货可赔</div>
                                        </div>
                                    </div> -->
								</div>
								<el-col>
									<div class="product-item-bottom">
										<div class="bottom-item">
											<div class="align">
												<div>给卖家留言：</div>
												<textarea class="liuyan" v-model="inputVal"></textarea>
											</div>
										</div>
									</div>
								</el-col>
								<el-col>
									<div class="product-item-bottom">
										<div class="bottom-item">
											<div>运送方式：
												<span class="fs24 cr9" v-if="shopItem.shipping_price==null">
													<text class="moneyIcon">￥</text>0.00
												</span>
												<span class="fs24 cr9" v-else-if="shopItem.shipping_price==0">免邮</span>
												<span class="fs24 cr9" v-else><span
														class="cr-666 fs-12 moneyIcon">￥</span>{{ shopItem.shipping_price }}</span>
												<span class="fs24 cr9" v-if="delivery_id == 0">物流配送</span>
												<span class="fs24 cr9" v-else-if="delivery_id == 1">到店消费</span>
												<span class="fs24 cr9"
													v-else-if="delivery_id == 2 && point">门店自提：{{ point.name }}</span>
											</div>

										</div>
									</div>
								</el-col>
								<el-col>
									<div class="product-item-bottom">
										<div class="bottom-item">
											<div>运费险：卖家赠送，退换货可赔</div>
										</div>
									</div>
								</el-col>
								<el-col>
									<div class="product-item-bottom">
										<div class="bottom-item">
											<div>优惠券：
												<el-select v-model="couponName" placeholder="请选择"
													@change="changeCoupon">
													<el-option v-for="(item,index) in couponList" :key="index"
														:label="item.coupon.name" :value="index">
													</el-option>
												</el-select>
											</div>
										</div>
									</div>
								</el-col>
								<el-col class="bottom-total-box">
									<div>
										<span style="width: 150px;">总金额：</span>
										<span style="width: 100px;"
											class="cr-ff0000">￥{{ shopItem.sale_real_total | toInt }}</span>
									</div>
									<div>
										<span style="width: 150px;">运费：</span>
										<span style="width: 100px;"
											class="cr-ff0000">￥{{ shopItem.shipping_price | toInt }}</span>
									</div>
									<div>
										<span style="width: 150px;">优惠券：</span>
										<span style="width: 100px;"
											class="cr-ff0000">-￥{{ shopItem.discount ? shopItem.discount : 0 }}</span>
									</div>
									<div class="total-price-box">
										<span style="width: 150px;">应付总额：</span>
										<span style="width: 100px;" class="total-price">
											<span class="fs-16">￥</span>
											<span>{{ shopItem.shopTotal | toInt }}</span>
										</span>
									</div>
									<div class="bg-f1">
										寄送至：
										{{ addressList&&addressList[activeIndex]&&addressList[activeIndex].provinceName || ''}}
										{{ addressList&&addressList[activeIndex]&&addressList[activeIndex].cityName || '' }}
										{{ addressList&&addressList[activeIndex]&&addressList[activeIndex].countyName || '' }}
										{{ addressList&&addressList[activeIndex]&&addressList[activeIndex].detailInfo || ''}}
										<span style="margin-left: 20px;">
											收货人：{{ addressList&&addressList[activeIndex]&&addressList[activeIndex].userName || ''}}
										</span>
									</div>
									<div>
										<button type="button" class="btn btn-success" @click.stop="pay">提交订单</button>
									</div>
								</el-col>
							</el-row>

						</div>
					</div>
				</div>
			</el-col>
		</el-row>
	</div>
</template>
<script>
	import {
		regionData,
		CodeToText,
		TextToCode,
		codeToText,
	} from "element-china-area-data"; //导入省市区
	import {
		Product
	} from '@/api/product';
	var product = new Product();
	import {
		Cart
	} from '@/api/cart';
	var cart = new Cart();
	import {
		User
	} from '@/api/user';
	var user = new User();
	import {
		Address
	} from '@/api/address';
	var address = new Address();
	import moment from '@/utils/moment';
	import helper from '@/utils/helper';
	export default {
		data() {
			return {
				addressList: [],
				activeIndex: 0,
				payList: [{
					name: '在线支付'
				}],
				payIndex: 0,
				address_value: '',
				radio: '1',
				showAddress: false,
				param: {
					from: '',
					id: '',
					is_group: 0,
					prom_type: '',
					prom_id: '',
					type: 0
				},
				productDetail: {},
				productCounts: 1,
				cartId: '',
				flag: '',
				fromCartFlag: true,
				// addressInfo: null,
				hiddenBox: true,
				all_buy_total: 0,
				amount: 0,
				scoreMoney: 0,
				hiddenCoupon: true,
				couponList: {}, //当前显示的优惠券列表
				type: 1,
				onSub: false,
				orderTOPayData: [],
				orderStatus: 0,
				inputVal: '', //备注
				couponName: '',
				address: '',
				delivery_id: 0,
				point: '',
				is_group: '',
				prom_id: '',
				prom_type: 1,
				group_order_id: '',
				table_id: '',
				sendType: [{
						'id': 0,
						'name': '物流配送'
					},
					{
						'id': 1,
						'name': '到店消费'
					},
				],
				//下单者信息
				full_name: '',
				tel: '',
				defaultCoupnIndex: 0, //优惠券默认index
				defaultAddress: {},
				amount_orign: '',
				shop_index: 0,
				platformSelect: {},
				options: regionData,
				selectedOptions: [],
				addAddressParam: {
					address_name: "",
					city_id: '',
					detailInfo: "",
					lat: "",
					lng: "",
					province_id: '',
					region_id: '',
					telNumber: "",
					type: "",
					userName: "",
					is_default: 0,
					provinceName: '',
					cityName: '',
					countyName: ''
				},
				province: [], //省
				city: [], //市
				area: [] //区
			}
		},
		created() {
			this.productCounts = this.$route.query.productCounts ? this.$route.query.productCounts : this.productCounts;
			this.param.from = this.$route.query.from;
			this.param.id = this.$route.query.id ? this.$route.query.id : '';
			this.param.is_group = this.$route.query.is_group ? this.$route.query.is_group : 0;
			this.param.prom_type = this.$route.query.prom_type ? this.$route.query.prom_type : '';
			this.param.prom_id = this.$route.query && this.$route.query.prom_id ? this.$route.query.prom_id : '';
			this.productDetail = this.$route.query.productDetail ? JSON.parse(this.$route.query.productDetail) : {};
			//
			if (this.param.from == 'buy') {
				//直接购买
				this.addCart(this.param);
			} else {
				//从购物车购买
				this.getDefault();
			}
			this.getAllAdd();
			this.getRegion(1, 1);
		},
		filters: {
			//
			toInt(val) {
				return helper.toInt(val);
			},
			// 时间格式化
			time(val) {
				return moment(val * 1000).format('YYYY-MM-DD HH:mm');
			},
		},
		methods: {
			//获取省市区
			getRegion(id, type) {
				//type 1省 2市 3区
				var param = {
					page: 1,
					'per-page': 30,
					id: id
				};
				var that = this;
				address.region(param).then(data => {
					if (type == 1) {
						that.province = data.items
					} else {
						if (type == 2) {
							that.city = data.items
						} else {
							that.area = data.items
						}
					}
				}).catch((res) => {
					that.$message.error(res.msg ? res.msg : '获取省市区失败！')
				})
			},
			//
			changeProvince(e) {
				this.addAddressParam.provinceName = this.province[e].name;
				this.addAddressParam.province_id = this.province[e].id;
				this.getRegion(this.province[e].id, 2)
				//防止重新选了省之后，市、区数据不变，要清空
				this.addAddressParam.cityName = '';
				this.addAddressParam.city_id = '';
				this.addAddressParam.countyName = '';
				this.addAddressParam.region_id = '';
			},
			changeCity(e) {
				this.addAddressParam.cityName = this.city[e].name;
				this.addAddressParam.city_id = this.city[e].id;
				//防止重新选了市之后，区数据不变，要清空
				this.addAddressParam.countyName = '';
				this.addAddressParam.region_id = '';
				this.getRegion(this.city[e].id, 3)
			},
			changeArea(e) {
				this.addAddressParam.countyName = this.area[e].name;
				this.addAddressParam.region_id = this.area[e].id;
			},
			//获取购物车信息
			addCart() {
				var flag = this.param.from;
				var is_group = this.param.is_group;
				var that = this;
				if (product.isSelectAll(this.productDetail).skustr) {
					this.selectLabel = product.isSelectAll(this.productDetail).selectLabel;
					var params = {
						'sku_id': product.isSelectAll(this.productDetail).skustr,
						'num': this.productCounts ? this.productCounts : 1,
						'prom_type': this.param.prom_type,
						'prom_id': this.prom_id,
						'type': 0
					}
					if (flag == 'cart') {
						params.type = 0;
						this.param.type = 0;
					} else {
						params.type = 1;
						this.param.type = 1;
					};
					cart.add(params).then(data => {
						var status = data.status;
						var msg = data.msg;
						that.param.id = msg;
						this.getDefault();

					}).catch(() => {

					});
				}
			},
			//获取去全部地址
			getAllAdd() {
				var param = {};
				var that = this;
				user.addressList(param).then(data => {
					that.addressList = data.items;
					for (let index = 0; index < data.items.length; index++) {
						if (data.items[index].is_default == 1) {
							that.activeIndex = index;
						}
					}
				}).catch(() => {

				});
			},
			//获取用户的默认地址
			getDefault() {
				var that = this
				/*通过接口获取用户默认的收货地址*/
				var address_param = {};
				user.defaultAddr(address_param).then(res => {
					var params = {};
					if (res.status == 1 && res.item && res.item.userName) {
						var item = res.item;
						if (res.item != null) {
							var addressInfo = {
								id: item.id,
								provinceName: item.provinceName,
								cityName: item.cityName,
								countyName: item.countyName,
								userName: item.userName,
								telNumber: item.telNumber,
								detailInfo: item.detailInfo,
								address_name: item.address_name,
							};
						}

						this.defaultAddress = addressInfo
						//设置填充上次默认的地址信息
						this.full_name = item.userName
						this.tel = item.telNumber
						params = {
							"type": this.param.type,
							"address_id": item.id,
							"id": this.param.id,
							"is_group": that.param.is_group,
							prom_type: this.prom_type,
							prom_id: this.prom_id
						}
					} else if (res.status == 0) {
						params = {
							"type": that.param.type,
							"id": that.param.id,
							"is_group": that.param.is_group,
							prom_type: that.param.prom_type,
							prom_id: that.param.prom_id
						}
					}
					params.delivery_id = that.delivery_id;
					that.param = params;
					that.orderInfo(that.param);
				}).catch((res) => {
					console.log(res);
				})

			},
			//
			orderInfo(params) {
				var that = this;
				user.confirm(params).then(data => {
					//获取购物车选定的商品信息（价格、数量、总价）
					if (data.status != 1) {
						if (data.status == -3) {
							that.$confirm(data.msg, '提示', {
								confirmButtonText: '重选地址',
								cancelButtonText: '取消',
								type: 'warning'
							}).then(() => {
								that.$message({
									type: 'success',
									message: '删除成功!'
								});
							}).catch(() => {
								that.$message({
									type: 'info',
									message: '已取消删除'
								});
							});
						} else {
							var msg=data.msg+',请重新选择'
							that.$confirm(msg, '提示', {
								confirmButtonText: '重新选择',
								cancelButtonText: '取消',
								type: 'warning'
							}).then(() => {
								that.$router.back();
							}).catch(() => {
								that.$router.back();
							});
						}
						return false;
					};
					this.amount = data.amount;
					var itemList = data.items;
					var orderDatas = [];
					for (let i in itemList) {
						orderDatas.push(itemList[i]);
					}
					orderDatas = this._getCaculateShopTotal(orderDatas)
					/***保存期最初的数据，仅用于计算，始终不能改变该值，避免多次选择优惠券原始价格被覆盖***/
					orderDatas.forEach((item, index) => {
						item.shopTotal_orign = item.shopTotal;
					})
					this.amount_orign = data.amount;
					/********** */
					this.orderTOPayData = orderDatas;
					this.amount = this.amount;
					this.scoreMoney = data.scoreMoney;
					this.all_buy_total = data.buy_total;
					//使用默认的优惠券
					orderDatas.forEach((item, shop_index) => {
						if (item.coupon && item.coupon.enable.length > 0) {
							that.couponList = item.coupon.enable;
							//构造不适用优惠券
							var uncoupon = {
								coupon: {
									name: '不使用优惠券',
									id: ''
								}
							};
							that.couponList.push(uncoupon);
							//记录选中的优惠券
							var index = that.defaultCoupnIndex; //获取选中的优惠券id
							if (index >= 0) {
								that.orderTOPayData[shop_index].couponSelected = that.couponList[index];
								that.couponName = that.couponList[index].coupon.name;
							}
							//计算使用优惠券后的金额
							that.couponCacu(shop_index);
						}
					})
				}).catch((res) => {

				})
			},
			//使用优惠券的金额计算
			couponCacu() {
				var data = this.orderTOPayData;
				var shop_discount_total = 0; //有所店铺优惠券累计优惠金额
				var platform_discount = 0; //平台券优惠金额
				data.forEach((shopData, shop_index) => {
					var coupon = shopData.couponSelected;
					if (coupon && coupon.coupon) {
						if (coupon.coupon.use_type == 2) { //折扣券
							//单个店铺价格
							var goods_price = -1;
							if (coupon.coupon.product_limiter_id) { //指定商品打折
								shopData.data.forEach((item, index) => {
									if (item.product_id == coupon.coupon.product_limiter_id) {
										goods_price = item.sale_price_real;
									}
								})
							} else { //全场打折
								shopData.data.forEach((item, index) => {
									if (item.shop_id == coupon.coupon.shop_id) {
										goods_price = shopData.sale_real_total;
									}
								})
							}
							if (goods_price < 0) {
								goods_price = 0;
							}
							var discount = goods_price * ((10 - coupon.coupon.use_money) / 10);
							discount = discount.toFixed(2);
							shop_discount_total = parseFloat(shop_discount_total) + parseFloat(discount);
							var rs = shopData.shopTotal_orign - discount;
							//店铺小计
							this.orderTOPayData[shop_index].shopTotal = rs;
							this.orderTOPayData[shop_index].discount = discount;

						} else if (coupon.coupon.use_type == 1) { //满减券
							var discount = coupon.coupon.use_money;
							//所有店铺累计的优惠券的优惠金额
							shop_discount_total = parseFloat(shop_discount_total) + parseFloat(discount);
							//店铺小计
							var shop_total = parseFloat(shopData.shopTotal_orign) - parseFloat(discount);
							this.orderTOPayData[shop_index].shopTotal = shop_total;
							this.orderTOPayData[shop_index].discount = discount;
						}
					} else {
						var shop_total = parseFloat(shopData.shopTotal_orign);
						this.orderTOPayData[shop_index].shopTotal = shop_total;
						this.orderTOPayData[shop_index].discount = 0;
					}
				})
				if (this.platformSelect && this.platformSelect.coupon) {
					var coupon = this.platformSelect;
					if (coupon.coupon.use_type == 2) { //折扣券
						var goods_price = -1;
						if (coupon.coupon.product_limiter_id) { //指定商品打折
							data.forEach((shopData, shopIndex) => {
								shopData.data.forEach((item, index) => {
									if (item.product_id == coupon.coupon.product_limiter_id) {
										goods_price = item.sale_price_real;
									}
								})
							})
						} else { //全场打折
							goods_price = this.$data.amount;
						}
						if (goods_price < 0) {
							goods_price = 0;
						}
						var discount = goods_price * ((10 - coupon.coupon.use_money) / 10);
						discount = discount.toFixed(2);
						platform_discount = parseFloat(platform_discount) + parseFloat(discount);

					} else if (coupon.coupon.use_type == 1) { //满减券
						var discount = coupon.coupon.use_money;
						platform_discount = parseFloat(platform_discount) + parseFloat(discount);
					}
				}
				this.amount = this.amount_orign - shop_discount_total - platform_discount; //优惠券优惠后的金额
			},
			//
			changeCoupon(e) {
				var index = e;
				var shop_index = this.shop_index;
				this.orderTOPayData[shop_index].couponSelected = this.couponList[index];
				this.couponName = this.couponList[index].coupon.name;
				if (this.couponName == '不使用优惠券') {
					this.couponReset();
				} else {
					//计算使用优惠券后的金额
					this.couponCacu(shop_index);
				}
			},
			//不使用优惠券，还原原本的数据
			couponReset() {
				this.defaultCoupnIndex = '';
				var shop_index = this.shop_index;
				this.orderTOPayData[shop_index].couponSelected = {};
				this.couponCacu();
			},
			// 小计
			_getCaculateShopTotal(orderDatas) {
				var len = orderDatas.length,
					total = 0;
				for (let i = 0; i < len; i++) {
					if (orderDatas[i].shipping_price == null) {
						orderDatas[i].shipping_price = 0;
					}
					total = parseFloat(orderDatas[i].sale_real_total).toFixed(2) * 100 + parseFloat(orderDatas[i]
						.shipping_price).toFixed(
						2) * 100;
					orderDatas[i].shopTotal = total / 100;
				}
				return orderDatas;
			},
			selectAddress(index) {
				this.activeIndex = index;
			},
			selectpay(index) {
				this.payIndex = index;
			},
			addAddress() {
				this.showAddress = !this.showAddress;
			},
			//
			close() {
				this.showAddress = !this.showAddress;
			},
			//
			openPopover(){
				this.showAddress =true;
			},
			//
			closePopover(){
				this.showAddress =false;
			},
			/*下单和付款*/
			pay() {
				if (this.orderStatus == 0) {
					var that = this;
					//拼团商品订阅信息
					if (this.is_group == 1 || this.is_group == 2) {
						wx.requestSubscribeMessage({
							tmplIds: [
								// '-pY1pLa-zvTo_R83LgLW1ieogbFOt6UzvBvmRmXEIS8',
								'PShQZMxVbkdmg10uHtviFiRWutnMwjwohxAyzsJcXTw',
								'1nFNMFqIKJJ5giaxm8kY5AOe4eEyfWzX7TKyPN6DHxg'
							],
							success(res) {
								that._firstTimePay();
							}
						})
					} else {
						that._firstTimePay();
					}
				} else {
					// this._oneMoresTimePay();
				}

			},
			/*第一次支付*/
			_firstTimePay() {
				var shops = [];
				var message = this.inputVal ? this.inputVal : '';
				var orderTOPayData = this.orderTOPayData;
				var len = orderTOPayData.length;
				for (let i = 0; i < len; i++) {
					var childLen = orderTOPayData[i].data.length;
					var cart_ids = [];
					for (let j = 0; j < childLen; j++) {
						cart_ids.push(parseInt(orderTOPayData[i].data[j].id));
					}
					var coupon_item_id = orderTOPayData[i].couponSelected ? orderTOPayData[i].couponSelected.id : ''
					var obj = {
						'id': orderTOPayData[i].shop.id,
						// 'product_id':orderTOPayData[i].data[i].product_id,
						'cart_ids': cart_ids,
						'citem_id': coupon_item_id, //优惠券id
						//'discount': 0,
						//'shipping_price': orderTOPayData[i].shipping_price,
						'mark': orderTOPayData[i].mark ? orderTOPayData[i].mark : '',
						'buy_total': orderTOPayData[i].buy_total,
						'amount': this.amount
					};
					shops.push(obj);
				};
				//用户是否已经有地址
				if(this.addressList.length==0){
					this.$message.error('请先添加收货地址~')
					return false;
				};
				var params = {
					shops: shops,
					type: this.param.type,
					scoreMoney: 0,
					aid: this.addressList[this.activeIndex].id,
					real_amount: this.amount,
					delivery_id: this.delivery_id,
					mark: this.inputVal,
					is_group: this.is_group,
					table_id: this.table_id,
					full_name: this.full_name,
					mobile: this.tel
				}
				if (this.group_order_id) {
					params.group_order_id = this.group_order_id
				}
				var that = this;
				//支付分两步，第一步是生成订单号，然后根据订单号支付
				user.doOrder(params).then(data => {
					this.onSub = false;
					//订单生成成功
					if (data.status == 1) {
						//更新订单状态
						that.fromCartFlag = false;
						//开始支付
						var params = {
							'parent_id': data.msg
						};
						that.$router.push({
							path: '/payment',
							query: {
								parent_sn: data.msg,
								entry: 2,
								is_group: that.param.is_group
							}
						}).catch(() => {

						});
					} else {
						this.$message.error(data.msg ? data.msg : '提交订单失败！');
					}
				}).catch(() => {

				});
			},
			handleAddress(e) {

			},
			handleChange(e) {
				console.log(e)
			},
			//
			createAddress() {
				var that = this;
				var param = {
					...this.addAddressParam
				};
				user.create(param).then(data => {
					if (data.status == 1) {
						that.$message.success('新建成功！');
						that.showAddress = false;
						this.getAllAdd();
						this.activeIndex = 0;
					} else {
						var msg = data.msg ? data.msg : '新建地址失败！'
						that.$message.error(msg);
					}
				}).catch((res) => {
					var msg = res.msg ? res.msg : '新建地址失败！'
					that.$message.error(msg);
				})

			}
		}
	}
</script>
<style scoped>
	@import '../styles/confirm'
</style>