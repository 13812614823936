import axios from 'axios'
import {
  Message,
  MessageBox
} from 'element-ui'
import {
  removeAuth
} from '@/utils/auth'
import router from '../router'
import store from '../store/index'
import qs from 'qs'
import { debounce } from 'throttle-debounce' //防抖节流插件throttle-debounce
import config from '@/config'

/**
 * 检查dom是否忽略
 * @param {*} e
 */
const clearCacheEnterLogin = debounce(500, () => {
  removeAuth().then(() => {
    location.reload() // 为了重新实例化vue-router对象 避免bug
  }).catch(() => {
    location.reload()
  })
})

const errorMessage = debounce(500, (message, type = 'error') => {
  Message({
    message: message,
    duration: 1500,
    type: type
  })
})

const confirmMessage = debounce(1000, (message, url) => {
  MessageBox.confirm(message, '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '不登录',
    closeOnClickModal: false,
    closeOnPressEscape: false,
    showClose: false,
    type: 'warning'
  }).then(() => {
    router.push({
      path: '/login',
      query: {}
    }).catch(()=>{
      
    });
  }).catch(() => {
  })
})

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
// 创建axios实例
const service = axios.create({
  //baseURL: process.env.BASE_API, // api 的 base_url
  baseURL: config.url, // 真实的url
  timeout: 600000 // 请求超时时间
})

// request拦截器
service.interceptors.request.use(

  config => {
    const flag = config.headers['Content-Type'] && config.headers['Content-Type'].indexOf('application/json') !== -1
    if (!flag) {
      const mult = config.headers['Content-Type'] && config.headers['Content-Type'].indexOf('multipart/form-data') !== -1
      if (mult) {
        config.data = config.data
      } else {
        config.data = qs.stringify(config.data)
      }
    } else {
      if (config.data === undefined || config.data === null) {
        // 不传参的情况下 json类型的提交数据，校准为 空对象
        config.data = {}
      }
    };
    //头部带登录信息
    const xapikey = localStorage.getItem('access_token');
    config.headers['x-api-key'] = xapikey;
    //get方法时，传参用params
    if (config.method == "get") {
      config.params = config.data;
    };
	// config.url ='http://www.yjshop.com/api/v1' + config.url
    config.url = 'https://mini.binon.com.cn/api/v1' + config.url
    return config
  },
  error => {
    // Do something with request error
    return Promise.reject(error)
  }
)

// response 拦截器
service.interceptors.response.use(
  response => {
    /**
     * code为非0是抛错
     */
	//存储当前页面路径
	const current_url = router.currentRoute&&router.currentRoute.path ? router.currentRoute.path : '';
	/* 首页是否展示全部商品分类的子分类 */
	/* if(current_url=='/indexMain'){
		store.commit('setShowNavChild',true);
	}else{
		store.commit('setShowNavChild',false);
	} */
	store.commit('setShowHeader',current_url);
	store.commit('setShowfooter',current_url);
    const res = response.data;
    if (response.data.errcode === 0 && response.config.responseType === 'blob') { // 文件类型特殊处理
      if (response.headers['content-disposition'] || (response.headers['content-type'] && response.headers['content-type'].indexOf('application/pdf') != -1)) {
        return response
      } else if (response.data) {
        const resultBlob = new Blob([response.data], { type: 'application/json' })
        const fr = new FileReader()
        fr.onload = function () {
          const result = JSON.parse(this.result)
          // 附件下载反馈的302 忽略
          if (result.msg && result.code !== 302) {
            errorMessage(result.msg, result.code == 1 ? 'success' : 'error')
          }
        }
        fr.readAsText(resultBlob)
      }
    } else if (res.errcode !== 0) {
      // 401	登录已失效
      if (res.errcode === 401) {
		var query=router.currentRoute.query;
        var path = router.currentRoute.path;
		var currentPage={
			query:query,
			path:path
		}
        //把当前的页面缓存起来，登录成功后进行跳转
        localStorage.setItem('currentPage', JSON.stringify(currentPage));
        confirmMessage('登录已失效,即将为您跳转登录！', path);
      } else if (res.errcode === 1005) {
        router.push('/indexMain')
      } else {
        if (res.errmsg) {
          
          Message({
            message: res.errmsg,
            duration: 1500,
            type: 'warning'
          })
        }
      }
      return Promise.reject(res)
    } else {
      return res
    }
  },
  error => {
    if (error.response) {
      const response = error.response
      if (response.errcode == 500) {
        errorMessage('网络错误，请检查您的网络')
      } else if (response.data && response.data.msg) {
        errorMessage(response.data.msg)
      }
    }
    return Promise.reject(error)
  }
)

export default service
