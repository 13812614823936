<template>
	<el-container id="detail">
		<div class="container detail-box" v-loading='loading' id='detailBox'>
			<el-row :gutter="10">
				<el-col class="goods-img" :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
					<img :src="productDetail.product.image[0].thumbImg"
						v-if="productDetail.product && productDetail.product.image">
				</el-col>
				<el-col class="goods-info" :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
					<h2 class="name">
						<span class="pre-sale-product" v-if="productDetail.product&&productDetail.product.is_future&&productDetail.product.is_future==1">预售</span>
						{{ productDetail.product && productDetail.product.name || '' }}
					</h2>
					<dl class="goods-price">
						<dt>价格：</dt>
						<!-- //多规格，还未选中规格时默认展示最低价格 -->
						<dd class="price-txt"
							v-if="productDetail.product&&productDetail.product.max_price&&productDetail.product.max_price!=productDetail.product.min_price&&allselect==0">
							<span class="fs-16">￥</span>
							<span>{{ productDetail.product && productDetail.product.min_price || '' }}</span>
							<span class="fs-14"
								v-if="productDetail.product && productDetail.product&&productDetail.product.max_price > 0 && productDetail.product.max_price != productDetail.product.min_price">起</span>
						</dd>
						<dd v-else-if="allselect==0" class="price1 fs28 lh48">
							<span class="fs-16">￥</span>
							<span
								v-if="productDetail.product&&productDetail.product.min_market_price && productDetail.product&&productDetail.product.min_market_price>0">
								{{productDetail.product.min_market_price | toInt}}
							</span>
							<span v-else-if="productDetail.product&&productDetail.product.min_price">
								{{productDetail.product.min_price | toInt}}
							</span>
						</dd>

						<dd class="price-txt" v-else-if="allselect==1">
							<span class="fs-16">￥</span>
							<span
								:class="productDetail.product&&productDetail.product.is_score==1 && skus.sale_price <=0 ? 'hide' : ''">
								{{skus.sale_price | toInt}}{{productDetail.product.is_score==1 ? '元' : ''}}</span>
							<span class="del-price" v-if="skus.market_price && skus.market_price>0">
								￥{{skus.market_price | toInt}}
							</span>
						</dd>
						<dd class="price-txt"
							v-else-if="allselect==1 && skus.prom&&skus.prom.status==1&&skus.prom.proming_status==1">
							<text class="moneyIcon">￥</text>{{skus.prom.price}}元<text class="prom_attr_price">秒杀</text>
						</dd>
						<dd class="price-txt"
							v-else-if="allselect==1 && skus.prom&&skus.prom.status==1&&skus.prom.proming_status==1">
							<text class="moneyIcon">￥</text>{{skus.prom.price}}元<text class="prom_attr_price">秒杀</text>
						</dd>
					</dl>
					<!-- <dl class="goods-price">
                        <dt>优惠：</dt>
                        <ul class="goods-coupon-list">
                            <a href="#">
                                <li class="goods-coupon-item">
                                    <img src="../assets/icon/receive-coupon.svg" />
                                    <span>满10元减1</span>
                                </li>
                            </a>
                            <a href="#">
                                <li class="goods-coupon-item">
                                    <img src="../assets/icon/receive-coupon.svg" />
                                    <span>满59元减10</span>
                                </li>
                            </a>
                            <div class="more-coupon">
                                <a href="#" @click="showCoupon('open')">更多优惠券</a>
                            </div>
                        </ul>
                    </dl> -->
					<!-- <dl class="goods-price goods-comment-box">
						<dt>评论：{{ productDetail.comment_count && productDetail.comment_count.all_count }}</dt>
						<dt class="text-center" style="color: #ddd;">|</dt>
						<dt class="eval-btn-box" :class="productDetail.isFavorite==1 ? 'eval-btn-box-active': ''"
							@click="isCollection">
							<span class="iconfont" style="margin-right: 5px;"
								:class="productDetail.isFavorite==1 ? 'icon-shoucangxuanzhong': 'icon-iconfontxingxing'"></span>
							收藏
						</dt>
					</dl> -->
					<dl class="goods-price attributes-box" v-for="(item, group_idx) in productDetail.attributes"
						:key="item.attribute_id">
						<dt class="attr-name">{{ item.attribute_name }}：</dt>
						<ul class="attr-list">
							<li class="attr-item" :class="attributes.is_select == 1 ? 'attr-item-active' : ''"
								:data-select-index="group_idx" :data-attr-index="c_index" data-attr-id="1"
								v-for="(attributes, c_index) in item.child" @click='selectAttr(group_idx, c_index)'>
								<!-- <span v-if="attributes.stock == 0" :data-select-index="group_idx"
                                    class='disabled'>{{ attributes.value_str }}</span> -->
								<span :class="attributes.is_select == 1 ? 'attr-active' : ''"
									:data-select-index="group_idx" :data-attr-index="c_index"
									data-attr-id="1">{{ attributes.value_str }}</span>
							</li>
						</ul>
					</dl>
					<div class="choose">
						<!-- 商品规格 -->
						<dl class="attr">
							<dt class="attr-name attr-num">购买数量：</dt>
							<dd class="attr-value">
								<input class="attr-value-input text-center" id="buy_num" placeholder=""
									@change.stop="changeCounts" @input.stop='changeCounts' v-model="num" />
								<div class="cart-img-box">
									<a href="#" id="add" @click.stop="changeNum('add')">
										<img src="../assets/images/up-on.svg" />
									</a>
									<a href="#" id="reduce" @click.stop="changeNum('reduce')">
										<img src="../assets/images/down-on.svg" />
									</a>
								</div>
							</dd>
						</dl>
						<dl class="goods-price">
							<dt class="attr-name">库存：</dt>
							<dt class="attr-name" v-if='allselect==0'>
								{{productDetail.product&&productDetail.product.stock}}</dt>
							<dt class="attr-name" v-if='allselect==1'>
								{{productDetail.product&&productDetail.product.skus&&productDetail.product.skus[attrIndex].stock}}
							</dt>
						</dl>
					</div>
					<div class="btn-box">
						<button type="button" class="btn btn-default" @click="addCart('cart', 0)">
							<span>加入购物车</span>
						</button>
						<button type="button" class="btn btn-success" @click="addCart('buy', 0)">
							<span>立即购买</span>
						</button>
					</div>
				</el-col>

			</el-row>
			<div class="container">
				<!-- 评论、联系客服 -->
				<el-row :gutter="10">
					<el-col :xs="8" :sm="8" :md="3" :lg="3" :xl="3" class="container-fluid">
						<div class="btn-mun-box">
							<div @click="isCollection" :class="productDetail.isFavorite==1 ? 'eval-btn-box-active': ''">
								<span class="iconfont" style="margin-right: 5px;"
									:class="productDetail.isFavorite==1 ? 'icon-shoucangxuanzhong': 'icon-iconfontxingxing'"></span>
								<span>收藏</span>
							</div>
						</div>
					</el-col>
					<el-col :xs="8" :sm="8" :md="3" :lg="3" :xl="3" class="container-fluid">
						<div class="btn-mun-box">
							<a class="immediately zixunBtn"
								href="http://shang.qq.com/wpa/qunwpa?idkey=0b70447a4e376c39e302c369172bfb0ea800251c50cdc450d94b3bfc0f08923b">
								<span class="iconfont icon-kefu" style="margin-right: 5px;"></span>
								<span>在线客服</span>
							</a>
						</div>
					</el-col>
				</el-row>
				<!-- end -->
			</div>
			<!-- 产品品论、详情 -->
			<div class="container comment-detail-box">
				<el-row :gutter="10" class="comment-top-nav">
					<el-col :class="btnActiveIndex == index ? 'comment-detail-btn-active' : ''"
						class="comment-detail-btn" v-for="(item, index) in list" :key="index" :xs="8" :sm="8" :md="3"
						:lg="3" :xl="3">
						<div @click="selectBtn(index)">
							{{ item.name }}
							<span v-if="item.num !== ''">(</span>
							{{ item.num !== '' ? item.num : '' }}
							<span v-if="item.num !== ''">)</span>
						</div>
					</el-col>
				</el-row>
				<el-row :gutter="10">
					<el-col class="goods-img tab-pane-bottom" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
						<!-- 详情 -->
						<div class="news-detail"
							v-if="btnActiveIndex == 0&&productDetail.product&&productDetail.product.content"
							v-html="productDetail.product.content"></div>
						<div v-else-if="btnActiveIndex == 0&&productDetail.product&&!productDetail.product.content">
							<el-empty description="暂无详情~"></el-empty>
						</div>
						<!-- 商品评论 -->
						<div class="bd" v-if="btnActiveIndex==1">
							<div v-if='comment_item.length>0'>
								<div v-for="(listItem,pIndex) in comment_item">
									<div class="list-item">
										<div class="weui-media-box__title">
											<div class="list-item-left">
												<div class="user" v-if="listItem.member">
													<text class="fr cr9 fs20 mgt5">{{listItem.member.username}}</text>
													<text v-if="listItem.member.avatar">
														<image :src='listItem.member.avatar' class="eval-img"></image>
													</text>
												</div>
												<div class="xingxing">
													<div v-for="item in listItem.total_stars">
														<text class="iconfont icon-xingxing"></text>
													</div>
													<div v-for="item in (5-listItem.total_stars)">
														<text class="iconfont icon-xingxing croff"></text>
													</div>
												</div>
											</div>
											<div>
												<text class="time">{{listItem.created_at | time}}</text>
											</div>
										</div>
										<div class="weui-media-box__desc">
											{{listItem.content}}
										</div>
										<div class="img-box">
											<div v-for="(item,index) in listItem.image">
												<image :src="item.url" @click="_predivBanner(item,index)" />
											</div>
										</div>
										<div v-for="replyItem in listItem.replys" v-for-item="replyItem">
											<div class="reply-box" v-if="listItem.replys.length>0">
												<div class="reply">商家回复：{{replyItem.content}}</div>
												<div class="reply-time">回复时间：{{replyItem.created_at | time}}</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div v-else>
								<el-empty description="暂无评论~"></el-empty>
							</div>
						</div>
						<!-- end -->
						<!-- 销售记录 -->
						<el-table class=" tab-pane-bottom" :data="tableData" border style="width: 100%"
							v-if="btnActiveIndex == 2">
							<el-table-column prop="date" label="买家" width="180">
							</el-table-column>
							<el-table-column prop="name" label="数量" width="180">
							</el-table-column>
							<el-table-column prop="address" label="成交时间">
							</el-table-column>
						</el-table>
						<!-- end -->
					</el-col>
				</el-row>
			</div>
			<div v-if="showMoreCoupon">
				<el-row>
					<div class="more-coupon-list" id="more-coupon-list">
						<div class="coupon-top-title-box">
							<div>领取优惠券</div>
							<div class="close-icon" @click="showCoupon('close')">x</div>
						</div>
						<div style="height: 64px;width: 250px;"></div>
						<ul>
							<li>
								<div class="coupon_new couponItem">
									<div class="coupon_content_new">
										<div class="couponLeftInfo">
											<div class="couponTopInfo">
												<div class="priceContainer"><span class="priceSign">￥</span><span
														class="savePrice">10</span></div>
												<div class="useCondition">满50可用</div>
											</div>
											<div class="validTime">2020.05.07-2024.03.08</div>
											<div class="couponBottomInfo">
												<div class="couponName">店铺优惠</div>
											</div>
										</div>
										<div class="couponRightInfo">
											<div class="couponReceive">立即领取</div>
										</div>
									</div>
								</div>
							</li>
							<li>
								<div class="coupon_new couponItem">
									<div class="coupon_content_new">
										<div class="couponLeftInfo">
											<div class="couponTopInfo">
												<div class="priceContainer"><span class="priceSign">￥</span><span
														class="savePrice">10</span></div>
												<div class="useCondition">满50可用</div>
											</div>
											<div class="validTime">2020.05.07-2024.03.08</div>
											<div class="couponBottomInfo">
												<div class="couponName">店铺优惠</div>
											</div>
										</div>
										<div class="couponRightInfo">
											<div class="couponReceive">立即领取</div>
										</div>
									</div>
								</div>
							</li>
						</ul>
					</div>
				</el-row>
			</div>
			<!-- end -->
		</div>
	</el-container>
</template>
<script>
	import moment from '@/utils/moment';
	import helper from '@/utils/helper.js';
	import {
		Product
	} from '@/api/product';
	var product = new Product();
	import {
		Cart
	} from '@/api/cart';
	var cart = new Cart();
	import {
		Evaluate
	} from '../api/evaluate.js';
	var evaluate = new Evaluate();
	import {
		Collection
	} from '../api/collection.js';
	var collection = new Collection();
	export default {
		data() {
			return {
				loading: false,
				id: '',
				productDetail: {
					attributes: []
				},
				attrIndex: '', //当前选中的规格值
				num: 1, //购买数量
				showMoreCoupon: false,
				value1: 1,
				skus: [],
				user: {},
				id: '',
				skuid: '',
				allselect: 0, //sku全选中状态，1表示全选中,
				selectLabel: '', //已选规格字符串
				productCounts: 1,
				prom_type: 0,
				prom_id: 0,
				list: [{
						name: '产品详情',
						num: ''
					},
					{
						name: '商品评论',
						num: 0
					}
				],
				btnActiveIndex: 0,
				comment_item: [], //评价列表
				comment_count: 0, // 评价数
				haopinglv: 100, //好评评率
			}
		},
		created() {
			//接受上一个页面传来的参数
			this.id = this.$route.query.id;
			this.loading = true;
			this.getDetail();
		},
		filters: {
			// 时间格式化
			time(val) {
				return moment(val * 1000).format('YYYY-MM-DD')
			},
			//转整
			toInt(val) {
				return helper.toInt(val);
			}
		},
		methods: {
			//详情
			getDetail() {
				var param = {
					product_id: this.id
				};
				product.productDetail(param).then(res => {
					this.loading = false;
					this.productDetail = res;
					this.list[1].num = res.comment_count.all_count;
					this.getEval();
					//只有一个规格值时，默认选中
					(res.attributes).forEach(attributes => {
						if (attributes.child.length == 1) {
							this.attrIndex = 0;
						}
					});

				}).catch(() => {
					this.loading = false;
				})
			},
			//获取商品评论
			getEval() {
				//获取商品评论
				var comment_param = {
					'goods_id': this.id,
					'per_page': 15,
					page: 1
				}
				evaluate.getByGoods(comment_param).then(data => {
					//好评率转化
					var haopinglv = data.comment_count.haopinglv * 100;
					that.comment_item = data.items;
					that.haopinglv = haopinglv;
					that.comment_count = data.comment_count.all_count;
					that.list[1].num = data.comment_count.all_count;
				})
			},
			// 收藏
			isCollection() {
				var param = {
					product_id: this.id
				}
				var isFavorite = this.productDetail.isFavorite
				if (isFavorite == 0) {
					collection.add(param).then(res => {
						var status = res.status;
						if (status == 1) {
							this.productDetail.isFavorite = 1
							this.$message.success('收藏成功~');

						} else {
							this.$message.error(res.msg ? res.msg : '收藏失败~');
						}
					}).catch(() => {

					})
				}
				if (isFavorite == 1) {
					collection.del(param).then(res => {
						var status = res.status;
						if (status == 1) {
							this.productDetail.isFavorite = 0
							this.$message.success('取消收藏成功~~');
						} else {
							this.$message.error(res.msg ? res.msg : '取消收藏失败~');
						}
					}).catch(() => {

					})

				}
			},
			/*切换商品属性*/
			selectAttr: function(selectIndex, attrIndex) {
				/* 切换规格时，购买数量设为默认值1 */
				this.num = 1;
				var productDetail = this.productDetail;
				var data = product.changAttr(selectIndex, attrIndex, productDetail);
				this.productDetail.attributes = data
				this.attrIndex = attrIndex
				var skusindex = product.changProductinf(this.productDetail);
				if (skusindex != undefined) {
					this.allselect = 1;
					this.skus = this.productDetail.skus[skusindex];
					this.prom_id = this.productDetail.skus[skusindex].prom ? this.productDetail.skus[skusindex].prom
						.id : '';
					this.prom_type = this.productDetail.product.prom_type ? this.productDetail.product.prom_type : '';
				} else {
					this.allselect = 0;
				};
				var back_param = {
					detail: this.productDetail,
					allselect: this.allselect
				};
			},
			/*调整商品数目*/
			changeCounts() {
				var maxCount = 0;
				if (this.allselect == 0) {
					maxCount = this.productDetail.product.stock
				} else if (this.allselect == 1) {
					maxCount = this.productDetail.product.skus[this.attrIndex].stock;
				};
				this.productCounts=this.num;
				if (this.num > maxCount) {
					this.num = maxCount;
					this.productCounts=this.num;
					if (maxCount == 0) {
						this.num = 1;
						this.$message.error('暂无库存~');
					} else {
						this.$message.error('大于库存~');
					}
				};
			},
			//购买数量变化
			changeNum(event) {
				if (event == 'add') {
					if (this.allselect == 0) {
						if (this.num >= this.productDetail.product.stock) {
							this.num = this.productDetail.product.stock;
							this.$message.error('购买数量大于库存数');
						} else {
							this.num++;
						}
					} else if (this.allselect == 1) {
						if (this.num >= this.productDetail.product.stock) {
							this.num = this.productDetail.product.skus[this.attrIndex].stock;
							this.$message.error('购买数量大于库存数');
						} else {
							this.num++;
						}
					}
					this.productCounts = this.num;
				} else {
					if (this.num > 1) {
						this.num--;
						this.productCounts = this.num;
					} else {
						this.$message.error('购买数量不得小于1');
					}
				}
			},
			//展示更多优惠券
			showCoupon(event) {
				if (event == 'open') {
					this.showMoreCoupon = true;
				} else {
					this.showMoreCoupon = false;
				}
			},
			//查看详情、评论
			selectBtn(index) {
				this.btnActiveIndex = index;
				if (index == 1) {
					this.getEval();
				}
			},
			//加入购物车或立即购买
			addCart(from, group) {
				var that = this;
				var flag = from;
				var is_group = group;
				if (product.isSelectAll(this.productDetail).skustr) {

					this.selectLabel = product.isSelectAll(this.productDetail).selectLabel;
					var params = {
						'sku_id': product.isSelectAll(this.productDetail).skustr,
						'num': this.productCounts ? this.productCounts : 1,
						'prom_type': this.prom_type,
						'prom_id': this.prom_id,
					};
					if (flag == 'cart') {
						params.type = 0;
					} else {
						params.type = 1;
					};
					cart.add(params).then(data => {
						var status = data.status,
							msg = data.msg;
						if (status == 1 && flag == 'cart') {
							this.$message.success('加入购物车成功！')
							var param = {
								num: data.num
							};
							that.productCounts = data.num;
							//加入购车成功后，重新获取购物车数据
							that.getCart();
						} else if (status == 1 && flag == 'buy') {
							//直接购买
							that.$router.push({
								path: '/confirm',
								query: {
									from: 'buy',
									id: msg,
									is_group: 0,
									prom_type: this.prom_type,
									prom_id: this.prom_id,
									productCounts: data.num,
									productDetail: JSON.stringify(this.productDetail)
								}
							}).catch(() => {

							});

						} else {
							this.$message.error(msg)
						}

					}).catch(() => {

					});
				} else {
					that.unselectLabel = product.isSelectAll(this.productDetail).unselectLabel;
					that.selectLabel = false;
					if (this.productDetail.product.stock == 0) {
						this.$message.error('暂无库存')
					} else {
						this.$message.error('请选择  ' + product.isSelectAll(this.productDetail).unselectLabel);
					}
				}

			},
			//
			getCart() {
				var that = this;
				var param = {}
				cart.list(param).then(data => {
					//跟新购物车数量
					that.$mStore.commit('setCart', data.items[1].data.length);
				}).catch(() => {

				})
			}
		}
	}
</script>
<style scoped>
	.pre-sale-product{
		background-color: #ff0000;
		color: #fff;
		margin-right: 5px;
		font-size: 22px;
		padding: 1px 4px;
		border-top-left-radius: 10px;
		border-bottom-right-radius: 10px;
	}
	.detail-box {
		padding: 0;
		margin-top: 20px;
		margin-bottom: 20px;
	}

	a {
		color: #333;
		text-decoration: none;
	}

	.goods-img {
		float: left;
		display: inline-div;
		padding: 0 40px 0 20px !important;
	}

	.goods-img img {
		width: 100%;
		height: 100%;
		border-radius: 4px;
	}

	.goods-info {
		float: left;
		display: inline-block;
		padding: 0 10px !important;
	}

	.goods-info .name {
		font-size: 22px;
		color: #1a1a1a;
		font-weight: normal;
		line-height: 22px;
		margin: 0px 0 20px !important;
	}

	.goods-info .goods-price {
		display: flex;
		justify-content: left;
		width: 100%;
		margin-top: 15px;
		clear: both;
	}

	.goods-info .goods-price dt {
		width: 100px;
		line-height: 30px;
		color: #333;
		float: left;
		font-weight: normal;
	}

	.goods-info .goods-price dd {
		width: 530px;
		height: 30px;
		line-height: 30px;
		font-size: 30px;
		color: #20b483;
		float: left;
	}

	.goods-coupon-list {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}

	.goods-coupon-list li {
		margin-bottom: 0 !important;
	}

	.goods-coupon-item {
		display: flex;
		align-items: center;
	}

	.list-items li {
		padding: 0 20px 0px 0px;
		box-shadow: none;
		border: none;
		vertical-align: middle;
		-ms-interpolation-mode: bicubic;
		-moz-interpolation-mode: bicubic;
		-webkit-interpolation-mode: bicubic;
		transition: all 0.3s ease;
		-webkit-transition: all 0.3s ease;
		-moz-transition: all 0.3s ease;
		-ms-transition: all 0.3s ease;
		width: 100%;
		float: left;
		margin-bottom: 20px;
	}

	.goods-coupon-item img {
		width: 30px;
		height: 30px;
		margin-right: 10px;
	}

	.more-coupon {
		float: left;
		color: #20b483;
		font-size: 14px;
		text-decoration: underline;
	}

	.more-coupon a {
		color: #20b483 !important;
	}

	.goods-info .goods-comment-box {
		border-top: 1px solid #eee;
		border-bottom: 1px solid #eee;
		padding: 20px 0;
	}

	.eval-btn-box {
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		border-radius: 20px;
		cursor: pointer;
	}

	.eval-btn-box:hover {
		background-color: #f1f1f1;
	}

	.eval-btn-box-active {
		color: #ff6600 !important;
	}

	.goods-info .goods-price {
		display: flex;
		justify-content: left;
		width: 100%;
		margin-top: 15px;
		clear: both;
	}

	.choose {
		position: relative !important;
	}

	.goods-info .choose {
		width: 100%;
		height: auto;
		margin-top: 15px;
	}

	.choose .attr {
		width: 100%;
		min-height: 30px;
		display: flex;
		clear: both;
		align-items: center;
	}

	.choose .attr .attr-name {
		width: 100px;
		float: left;
		font-weight: normal;
	}

	.choose .attr .attr-value {
		width: calc(100% - 100px);
		float: left;
		display: flex;
	}

	.attr-value-input {
		border: 1px solid #ddd;
		padding: 5px;
		width: 80px;
	}

	.choose .attr .attr-value .cart-img-box {
		display: inline-grid;
	}

	.choose .attr .attr-value .cart-img-box img {
		width: 15px;
		height: 15px;
		margin-left: 5px;
		border: 1px solid #eee;
		padding: 2px;
	}

	.goods-info .btn-box {
		width: 600px;
		/* height: 40px; */
		margin: 30px 0px;
		display: flex;
		justify-content: flex-start;
	}

	.btn-default,
	.btn-success {
		width: 150px;
		padding: 11px 0;
		margin-right: 20px;
	}

	.btn-default {
		color: #333;
		border-color: #ccc;
		background-color: #fff;
	}

	.btn-default:hover {
		border: 1px solid #20b483;
		color: #20b483;
	}

	.btn-success {
		background-color: #20b483 !important;
		border-color: #20b483;
	}

	.btn-mun-box {
		display: flex;
		justify-content: left;
		padding: 0;
	}

	.btn-mun-box div {
		cursor: pointer;
		display: flex;
		align-items: center;
	}

	.btn-mun-box a img {
		width: 15px;
		height: 15px;
		margin-left: 10px;
	}

	.attr-list {
		display: flex;
		flex-wrap: wrap;
		width: calc(100% - 100px);
	}

	.attr-item {
		background-color: #f1f1f1;
		margin: 10px 0;
		line-height: 30px;
		padding: 0 10px;
		border-radius: 4px;
		margin-right: 10px;
		cursor: pointer;
		min-width: 60px;
		text-align: center;
	}

	.attr-item-active {
		border: 1px solid #20b483;
		background-color: #e2f9f2;
		color: #20b483;
		font-weight: bold;
	}

	/* 商品评论，详情 */
	.product-bottom .nav-tabs {
		margin-bottom: 40px;
	}

	.nav-tabs {
		border-bottom: 1px solid #ddd;
	}

	.nav-tabs>li {
		float: left;
		margin-bottom: -1px;
	}

	.nav>li {
		position: relative;
		display: div;
	}

	.nav-tabs>li {
		float: left;
		margin-bottom: -1px;
	}

	.nav-tabs>li.active>a,
	.nav-tabs>li.active>a:focus,
	.nav-tabs>li.active>a:hover {
		cursor: default;
		color: #555;
		border: 1px solid #ddd;
		border-bottom-color: transparent;
		background-color: #fff;
	}

	.nav-tabs>li.active>a,
	.nav-tabs>li.active>a:hover,
	.nav-tabs>li.active>a:focus {
		cursor: default;
		color: #555;
		border: 1px solid #ddd;
		border-bottom-color: transparent;
		background-color: #fff;
	}

	.product-bottom .col-md-2 a {
		text-align: center;
	}

	.nav-tabs>li>a {
		line-height: 1.42857143;
		margin-right: 2px;
		border: 1px solid transparent;
		border-radius: 4px 4px 0 0;
	}

	.nav>li>a {
		position: relative;
		display: div;
		padding: 10px 15px;
	}

	.comment-detail-box .comment-detail-btn {
		border: 1px solid #eee;
		padding: 0px 20px;
		text-align: center;
		cursor: pointer;
		height: 56px;
		line-height: 56px;
	}

	.comment-top-nav {
		margin-left: 15px;
		margin-right: 15px;
		border-bottom: 1px solid #eee;
	}

	.comment-detail-btn-active {
		color: #20b483;
		border-bottom: 4px solid #20b483 !important;
	}

	.comment-detail-btn-active div {
		color: #20b483;
	}

	.news-detail {
		padding: 20px 0;
	}

	/* 表格 */
	.tab-pane-bottom .table {
		background-color: #f9f9f9;
		color: #333;
	}

	.table {
		width: 100%;
		max-width: 100%;
		margin-bottom: 20px;
		background-color: #f9f9f9 !important;
		color: #333;
		margin-top: 40px;
	}

	.el-table {
		margin-top: 40px;
	}

	.table>tbody>tr {
		align-items: center;
		display: flex;
		border-top: 1px solid #ddd;
	}

	.table>tbody>tr>td,
	.table>tbody>tr>th,
	.table>tfoot>tr>td,
	.table>tfoot>tr>th,
	.table>thead>tr>td,
	.table>thead>tr>th {
		padding: 30px;
		border-top: none !important;
		border-bottom: none;
	}

	.table>tbody>tr>td:first-child {
		width: 20%;
		background-color: #f9f9f9 !important;
	}

	.table>tbody>tr>td:last-child {
		width: 80%;
		background-color: #f9f9f9 !important;
	}

	.table>tbody>tr input,
	.table>tbody>tr textarea {
		padding: 20px;
		width: 600px;
		border: 1px solid #ddd;
	}

	.product-detail-table,
	.record-table {
		width: 600px;
		margin-bottom: 20px;
		border: 1px solid #ddd;
		background-color: #f9f9f9;
	}

	.product-detail-table th,
	.product-detail-table tr,
	.product-detail-table td,
	.record-table th,
	.record-table tr,
	.record-table td {
		padding: 20px;
	}

	/* end */
	/* 查看更多优惠券 */
	.coupon-top-title-box {
		display: flex;
		justify-content: space-between;
		padding: 20px;
		font-size: 16px;
		position: fixed;
		width: 300px;
		height: 64px;
		border-bottom: 1px solid #e3e2e8;
		cursor: move;
		background-color: #fff;
		margin-bottom: 64px;
	}

	.coupon-top-title-box .close-icon {
		cursor: pointer;
		width: 30px;
		display: flex;
		justify-content: center;
	}

	.more-coupon-list {
		display: div;
		position: fixed;
		background: #fff;
		margin: auto;
		overflow: auto;
		z-index: 9032;
		border-radius: 2px;
		box-shadow: 0 0 10px rgba(0, 0, 0, .15);
		animation: fk-popupWindowVT .3s ease;
		left: 769px;
		top: 159.5px;
		height: 400px;
		width: 300px;
	}

	#more-coupon-list::-webkit-scrollbar-thumb {
		/*滚动条里面的滑块*/
		background: white;
		border-radius: 10px;
	}

	.more-coupon-list ul {
		list-style: none;
		padding: 20px 0px;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}

	.couponItem {
		width: 250px;
		height: 106px;
		background-color: #77c673;
		color: #fff;
		padding: 0 10px;
		border-radius: 6px;
		margin-bottom: 10px;
		cursor: pointer;
		margin-left: 0 !important;
	}

	.couponTopInfo {
		display: flex;
		justify-content: space-between;
		align-items: end;
	}

	.savePrice {
		font-size: 36px;
		font-weight: bold;
	}

	.useCondition {
		text-align: right;
		font-size: 14px;
		font-weight: bold;
	}

	.coupon_content_new {
		display: flex;
		align-items: center;
	}

	.couponLeftInfo {
		width: 200px;
		padding: 0 10px;
	}

	.couponRightInfo {
		width: 50px;
		writing-mode: vertical-lr;
		text-align: center;
		display: inline-flex;
		border-left: 1px dotted #eee;
	}

	.validTime {
		text-align: right;
		font-size: 13px;
	}

	.couponName {
		background-color: hsla(0, 0%, 100%, .1);
		padding: 2px 5px;
		margin-top: 5px;
	}

	.couponReceive {
		padding-top: 10px;
		letter-spacing: 10px;
		display: flex;
		writing-mode: vertical-lr;
		justify-content: center;
		align-items: center;
	}

	.del-price {
		font-size: 13px;
		color: #999;
		text-decoration: line-through;
		margin-left: 5px;
	}

	/* 评论列表 */
	.hd div text {
		padding-left: 5px;
	}

	.list-item {
		padding: 15px;
		background-color: #fff;
		margin-bottom: 1px;
		margin: 8px 2%;
		border-radius: 8px;
	}

	.list-item-left {
		display: flex;
		justify-content: flex-start;
	}

	.list-item .weui-cell {
		line-height: 1.8;
	}

	.icon-dayuhao {
		font-size: 12px;
	}

	.plr10 {
		padding: 0 8px;
	}

	.list-item .icon-xingxing {
		font-size: 14px;
		color: #ff6600;
	}

	.list-item .weui-media-box__title {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 8px;
	}

	.list-item .img-box {
		margin: 8px 0;
	}

	.list-item .img-box image {
		width: 50px;
		height: 50px;
		margin-right: 4px;
	}

	.list-item .time {
		display: block;
		font-size: 10px;
		color: #999;
		text-align: right;
	}

	.weui-panel {
		border: none;
	}

	.weui-media-box__desc {
		line-height: 1.5;
	}

	.icon-xingxing {
		margin-left: 5px;
	}

	.user {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.eval-img {
		width: 20px;
		height: 20px;
		border-radius: 50%;
		margin-left: 6px;
	}

	.reply-box {
		padding-top: 15px;
	}

	.reply {
		font-size: 12px;
		color: #999;
		line-height: 19px;
	}

	.reply-time {
		font-size: 10px;
		color: #999;
		line-height: 14px;
	}

	.xingxing {
		padding-left: 10px;
	}

	/* 评论列表end */

	/* 查看更多优惠券end */
	/* end */
	/* 响应式 */
	@media only screen and (max-width: 767px) {
		.goods-img {
			float: left;
			display: inline-div;
			padding: 0 10px !important;
		}

		.goods-info .name {
			font-size: 16px;
			margin: 20px 0 !important;
		}

		.goods-info .goods-price dd {
			font-size: 24px;
		}

		.goods-info {
			padding: 0 20px !important;
			height: auto;
		}

		.attr-num {
			width: 140px !important;
		}

		.btn-default,
		.btn-success {
			width: 120px !important;
			padding: 8px 0;
			margin-right: 20px;
		}

		.btn-box {
			width: 100% !important;
			display: flex;
			justify-content: flex-start;
		}

		.btn-mun-box {
			padding: 0 !important;
		}
	}
</style>