<template>
	<div ref="outer" class="app-container">
		<el-container id="app">
			<el-header class="top-login-box" v-if="!$mStore.state.isLoginPage">
				<el-row :gutter="8" class="container">
					<el-col :xs="12" :sm="10" :md="16" :lg="16" :xl="16" class="hidden-sm-and-down">
						<!-- <div class="grid-content bg-purple header-left" @click.stop="backIndex">
							<img class="logo-img" src="../src/assets/logo-1.png" />
							<div class="grid-content bg-purple">比农出品</div>
						</div> -->
					</el-col>
					<el-col :xs="8" :sm="8" :md="2" :lg="2" :xl="2" v-if="!$mStore.state.isShowHeader">
						<router-link to="/indexMain">
							<div class="grid-content bg-purple register-txt">首页</div>
						</router-link>
					</el-col>
					<el-col class="hidden-sm-and-down" :xs="8" :sm="8" :md="1" :lg="1" :xl="1" v-if="!$mStore.state.hasLogin">
						<router-link :to="'/login?type=1'">
							<div class="grid-content bg-purple register-txt">登录</div>
						</router-link>
					</el-col>
					<el-col class="hidden-sm-and-down" :xs="8" :sm="8" :md="1" :lg="1" :xl="1">
						<router-link :to="'/login?type=2'">
							<div class="grid-content bg-purple register-txt">注册</div>
						</router-link>
					</el-col>
					<el-col class="hidden-sm-and-down" :xs="8" :sm="8" :md="12" :lg="12" :xl="12">
						<div class="grid-content bg-purple-light cart-txt">&nbsp;</div>
					</el-col>
					<el-col :xs="8" :sm="8" :md="2" :lg="2" :xl="2">
						<div class="grid-content bg-purple-light cart-txt" @click.stop="toMy('/cart','')">
							购物车({{ $mStore.state.cartNum }})</div>
					</el-col>
					<el-col :xs="8" :sm="8" :md="2" :lg="2" :xl="2">
						<div class="grid-content bg-purple-light my-txt" 
							@click.stop="toMy('/myorder','1-1')">我的订单</div>
					</el-col>
					<el-col :xs="8" :sm="8" :md="2" :lg="2" :xl="2">
						<!-- <router-link to="/myorder"> -->
						<div class="grid-content bg-purple-light my-txt" @click.stop="toMy('/myorder','1-1')">个人中心</div>
						<!-- </router-link> -->
					</el-col>
				</el-row>
			</el-header>
			<div style="width: 100%;height: 50px;"></div>
			<!-- <el-header class="website-log-box hidden-sm-and-down" v-if="!$mStore.state.isLoginPage">
				<el-row :gutter="8" class="container website-log-box-item" type="flex" justify="space-around">
					<el-col :xs="8" :sm="8" :md="6" :lg="8" :xl="10">
						<div class="website-name" @click.stop="backIndex">比农出品</div>
					</el-col>
					<el-col class="website-log-box-right" type="flex" :xs="16" :sm="16" :md="8" :lg="8" :xl="10">
						<input class="search-input" placeholder="请输入商品名称搜索" v-model="input" clearable>
						</input>
						<el-button type="success" icon="el-icon-search" @click="search">搜索</el-button>
					</el-col>
					<el-col :md="10" class="hidden-sm-and-down"></el-col>
				</el-row>
			</el-header> -->
			<!-- 导航PC -->
			<el-header class="website-log-box hidden-xs-only" :class="$mStore.state.isShowHeader ? '' : 'none-dis'"
				v-if="!$mStore.state.isLoginPage">
				<div class="pc-nav-bg">
					<el-row :gutter="10" class="container nav-list-box">
						<el-col :xs="3" :sm="7" :md="7" :lg="7" :xl="7">
							<div class="website-name" @click.stop="backIndex">比农出品</div>
						</el-col>
						<!-- 全部商品分类 -->
						<el-col :xs="24" :sm="4" :md="4" :lg="4" :xl="4" class="pc-parent-nav">
							<div trigger="click" placement='bottom-start' class="">
								<div class="pc-all-cate-title" @click="changeShowChid($mStore.state.showNavChild)">
									<span class="el-dropdown-link">
										{{ cateList.name}}
									</span>
									<span class="iconfont icon-liebiao"></span>
								</div>
								<div class="pc-child-nav">
									<div v-for="(child, c_index) in cateList.child" :key="c_index"
										class="el-dropdown-link-item">
										<div @click.stop="toChild(child,c_index)">
											<div>{{ child.cat_name }}</div>
											<div class="pc-child-nav-son">
												<span v-for="(son,s_index) in child.sons" @click.stop="toChild(son,'')"
													v-if="child.sons&&child.sons.length>0">{{son.cat_name}}</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</el-col>
						<!-- 全部商品分类end -->
						<el-col :xs="24" :sm="2" :md="2" :lg="2" :xl="2" v-for="(item, index) in navList" :key="index"
							class="pc-nav-item">
							<div v-if="item.child && item.child.length > 0" @click="selectNav(index)">
								<el-dropdown class="pc-parent-nav">
									<span class="el-dropdown-link"
										:class="index == activeIndex ? 'el-dropdown-link-active' : ''">
										<router-link :class="index == activeIndex ? 'el-dropdown-link-active' : ''"
											:to="item.url">
											{{ item.name}}<i
												class="el-icon-arrow-down el-icon--right"></i></router-link>
									</span>
								</el-dropdown>
							</div>
							<div class="grid-content el-dropdown-link bg-purple" @click="selectNav(index)"
								:class="index == activeIndex ? 'el-dropdown-link-active' : ''" v-else>
								<router-link :to="item.url"
									:class="index == activeIndex ? 'el-dropdown-link-active' : ''">
									{{ item.name}}
								</router-link>
							</div>
						</el-col>
					</el-row>
				</div>
			</el-header>
			<!-- 导航PC end -->
			<!-- 导航app -->
			<el-header class=" hidden-sm-and-up" v-if="!$mStore.state.isLoginPage"
				:class="showAppNav ? 'website-log-box' : 'website-log-box-two'">
				<el-row class="container app-nav">
					<el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12" :class="showAppNav ? 'nav-bottom' : ''">
						<div class=" header-left">
							<img class="app-logo-img" src="../src/assets/logo-fff.jpg" />
							<div class="website-name" @click.stop="backIndex">比农出品</div>
						</div>
					</el-col>
					<el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12" class="mune-icon"
						:class="showAppNav ? 'nav-bottom' : ''">
						<!-- <i class="el-icon-menu" @click="openNav"></i> -->
						<div class="iconfont icon-liebiao" @click="openNav"></div>
					</el-col>
					<el-row :gutter="10" class="container nav-list-box hidden-sm-and-up" v-if="showAppNav">
						<el-col :xs="24" :sm="24" :md="3" :lg="3" :xl="3" v-for="(item, index) in navList" :key="index">
							<div v-if="item.child && item.child.length > 0" @click="selectNav(index)">
								<el-dropdown :class="item.name=='产品分类' ? 'has-child' : ''">
									<span class="el-dropdown-link"
										:class="index == activeIndex ? 'el-dropdown-link-active' : ''">
										<router-link :class="index == activeIndex ? 'el-dropdown-link-active' : ''"
											:to="item.url">
											{{ item.name }}{{item.child}}
											<i class="el-icon-arrow-down el-icon--right"></i>
										</router-link>
									</span>
									<el-dropdown-menu slot="dropdown">
										<el-dropdown-item v-for="(child, c_index) in item.child" :key="c_index"
											class="el-dropdown-link-item">
											<div @click="toChild(child,c_index)">
												{{ child.cat_name }}
											</div>
										</el-dropdown-item>
									</el-dropdown-menu>
								</el-dropdown>
							</div>
							<div class="grid-content el-dropdown-link bg-purple" @click="selectNav(index)"
								:class="index == activeIndex ? 'el-dropdown-link-active' : ''" v-else>
								<router-link :to="item.url"
									:class="index == activeIndex ? 'el-dropdown-link-active' : ''">
									{{ item.name}}</router-link>
							</div>
						</el-col>
					</el-row>
				</el-row>
			</el-header>
			<!-- 导航app end -->
			<el-container class="main-body">
				<el-main>
					<!-- <IndexMain v-if="is_index"/> -->
					<router-view />
				</el-main>
			</el-container>
			<!-- 底部 -->
			<el-footer v-if="!$mStore.state.isLoginPage">
				<div class="assure-div">
					<el-row class="container assure-box" :class="$mStore.state.isShowfooter ? '' : 'none-dis'">
						<el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6">
							<div class="assure-item">
								<div class="iconfont icon-zantong"></div>
								<div>
									<div class="assure-title">正品保障</div>
									<div class="assure-dec">Authentic guarantee</div>
								</div>
							</div>
						</el-col>
						<el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6">
							<div class="assure-item">
								<div class="iconfont icon-anquanbaozhang"></div>
								<div>
									<div class="assure-title">安全保障</div>
									<div class="assure-dec">Security assurance</div>
								</div>
							</div>
						</el-col>
						<el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6">
							<div class="assure-item">
								<div class="iconfont icon-qianbao"></div>
								<div>
									<div class="assure-title">价格保证</div>
									<div class="assure-dec">Price guarantee</div>
								</div>
							</div>
						</el-col>
						<el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6">
							<div class="assure-item">
								<div class="iconfont icon-daifahuo"></div>
								<div>
									<div class="assure-title">快速发货</div>
									<div class="assure-dec">Lightning delivery</div>
								</div>
							</div>
						</el-col>
					</el-row>
				</div>
				<el-row class="container box">
					<el-col :xs="12" :sm="12" :md="4" :lg="4" :xl="4">
						<ul class="footer-ul">
							<li class="tit">关于我们</li>
							<li>
								<div @click="toAbout('introduction')">公司简介</div>
								<!-- <router-link to="/about#introduction">公司简介</router-link> -->
							</li>
							<li>
								<div @click="toAbout('fuwu')">服务内容</div>
								<!-- <router-link to="/about#fuwu">服务内容</router-link> -->
							</li>
							<li>
								<div @click="toAbout('jingying')">主营产品介绍</div>
								<!-- <router-link to="/about#jingying">主营产品介绍</router-link> -->
							</li>
						</ul>
					</el-col>
					<el-col :xs="0" :sm="0" :md="1" :lg="1" :xl="1"></el-col>
					<el-col :xs="12" :sm="12" :md="4" :lg="4" :xl="4">
						<ul class="footer-ul">
							<li class="tit">服务保障</li>
							<li><a href="#">正品保证</a></li>
							<li><a href="#">退货返运费</a></li>
							<li><a href="#">7天无理由退换</a></li>
						</ul>
					</el-col>
					<el-col :xs="0" :sm="0" :md="1" :lg="1" :xl="1"></el-col>
					<el-col :xs="12" :sm="12" :md="4" :lg="4" :xl="4">
						<ul class="footer-ul">
							<li class="tit">商家服务</li>
							<li>
								<div class="cursor" @click="toTxt('common-questions')">常见问题</div>
							</li>
							<li>
								<div class="cursor" @click="toTxt('training-center')">培训中心</div>
							</li>
						</ul>
					</el-col>
					<el-col :xs="0" :sm="0" :md="1" :lg="1" :xl="1"></el-col>
					<el-col :xs="12" :sm="12" :md="4" :lg="4" :xl="4">
						<ul class="footer-ul">
							<li class="tit">配送方式</li>
							<li>
								<div class="cursor">物流配送</div>
							</li>
							<li>
								<div class="cursor">到店自提</div>
							</li>
						</ul>
					</el-col>
					<el-col :xs="0" :sm="0" :md="1" :lg="1" :xl="1"></el-col>
					<el-col :xs="12" :sm="12" :md="4" :lg="4" :xl="4">
						<ul>
							<!-- <li class="tit">联系我们</li> -->
							<li class="tit">
								<div style="width: 100%;">
									<img src="../src/assets/erweima.jpg" class="weixin">
									<div class="saoma">扫一扫，体验小程序</div>
								</div>
							</li>
						</ul>
					</el-col>
				</el-row>
			</el-footer>
			<div class="sideBox hidden-xs-only" v-if="!$mStore.state.isLoginPage">
				<ul>
					<li>
						<div class="sideBox-0 " @click="toKefu">
							<img src="../src/assets/cebian-icon/wode.png" />
						</div>
						<p style="">个人中心</p>
					</li>
					<!-- <li>
						<a href="http://wpa.qq.com/msgrd?v=3&uin=&site=qq&menu=yes" class="sideBox-0 ">
							<img src="../src/assets/cebian-icon/kefu.png" />
						</a>
						<p>联系客服</p>
						<a href="http://wpa.qq.com/msgrd?v=3&uin=&site=qq&menu=yes" class="sideBox-1"
							style="display: none;">
							<div style="float: left;"><img src="../src/assets/kefu-01.png" /></div>
							<p style="float: left;">咨询在线客服</p>
						</a>
					</li> -->
					<li class="erweima-box">
						<div class="sideBox-0 sideBox-0-3 ">
							<img src="../src/assets/cebian-icon/xiaochengxu.png" />
						</div>
						<p>扫码体验</p>
						<a href="#" class="sideBox-3" style="display: none;">
							<img class="er-img" src="../src/assets/erweima.jpg" />
							<div>扫码体验微信小程序</div>
						</a>
					</li>
					<li>
						<el-backtop target=".app-container" :bottom="100" class="">
							<img class="img" src="../src/assets/cebian-icon/zhiding.png" />
							<p style="width: 50px;margin-left: 10px;">回到顶部</p>
						</el-backtop>
					</li>
				</ul>
			</div>
			<!-- 底部end -->
		</el-container>
	</div>
</template>

<script>
	import 'bootstrap/dist/js/bootstrap.js'
	import 'bootstrap/dist/css/bootstrap.min.css'
	import 'bootstrap/dist/js/bootstrap.min'
	import router from './router';
	import IndexMain from './components/indexMain.vue'
	import {
		Cart
	} from '@/api/cart';
	var cart = new Cart();
	import {
		Adv
	} from '@/api/adv';
	var adv = new Adv();
	import {
		Product
	} from '@/api/product';
	var product = new Product();
	export default {
		components: {
			IndexMain
		},
		data() {
			return {
				toPath: "", //下一个页面
				hasLogin: false,
				cartNum: 0, //购物车数量
				showAppNav: false,
				activeIndex: 0,
				is_index: true,
				input: '',
				navIndex: 0,
				cateList: {
					name: '产品分类',
					url: '',
					child: []
				},
				navList: [
					/* {
					  name: '产品分类',
					  url: '',
					  child: []
					}, */
					{
						name: '首页',
						url: '/indexMain'
					},
					/* {
					  name: '产品分类',
					  url: '',
					  child: []
					}, */
					/* {
						name: '全部产品',
						url: '/allproduct'
					}, */
					{
						name: '新品上市',
						url: '/newproduct'
					},
					{
						name: '推荐产品',
						url: '/recommend'
					},
					{
						name: '优惠专区',
						url: '/couponlist'
					},
					{
						name: '新闻中心',
						url: '/news'
					},
					{
						name: '关于我们',
						url: '/about'
					}
				],
				urls: [
					'https://24084278.s61i.faimallusr.com/2/AD0Itv69CxACGAAg_MKG9wUoiOygFDCADziiBA.jpg',
					'https://24084278.s61i.faimallusr.com/2/AD0Itv69CxACGAAg_MKG9wUoiOygFDCADziiBA.jpg'
				],
				categoryData: [],
				oldNavList: []
			}
		},
		computed: {
			currentPath() {
				return this.$route.path;
			}
		},
		watch: {
			$route(to, from) {
				if (to.path != from.path) {
					this.activeIndex = this.$route && this.$route.query && this.$route.query.activeIndex ? this.$route
						.query.activeIndex : 0;
				}
				//监听当前页面是否是登录页面
				if (to && to.path && to.path == '/login') {
					this.$mStore.commit('setIsLoginPage', true)
				} else {
					this.$mStore.commit('setIsLoginPage', false)
				}
			}
		},
		created() {
			this.activeIndex = this.$route && this.$route.query && this.$route.query.activeIndex ? this.$route.query
				.activeIndex : this.activeIndex;
			//登录状态
			var apiKey = localStorage.getItem('access_token');
			if (apiKey) {
				this.hasLogin = true;
				this.$mStore.state.hasLogin = true;
				//获取购物车数量
				this.getCart();
			} else {
				this.hasLogin = false
				this.$mStore.state.hasLogin = false;
			};
			this.getCategory();
		},
		mounted() {

		},
		updated() {

		},
		methods: {
			backIndex() {
				this.$router.push({
					path: '/indexMain',
					query: {}
				}).catch(() => {

				});
			},
			//
			toMy(url, query) {
				this.$router.push({
					path: url,
					query: {
						index: query
					}
				}).catch(() => {

				});
			},
			//
			changeShowChid(status) {
				this.$mStore.commit('setShowNavChild', (!status));
			},
			//搜索
			search() {
				this.$router.push({
					path: '/allproduct',
					query: {
						input: this.input
					}
				}).catch(() => {

				});
			},
			selectNav(index) {
				this.activeIndex = index;
				this.showAppNav = !this.showAppNav;
				if (index != 0) {
					this.is_index = false;
				} else {
					this.is_index = true;
				}
			},
			//
			openNav() {
				this.showAppNav = !this.showAppNav;
			},
			//
			toChild(item, c_index) {
				this.showAppNav = !this.showAppNav;
				this.activeIndex = 1; // 
				this.$router.push({
					path: '/allproduct',
					query: {
						id: item.category_id,
						name: item.cat_name,
						index: c_index
					}
				}).catch(() => {

				});
			},
			//
			getCart() {
				var that = this;
				var param = {};
				var num = 0; //购物车数量
				cart.list(param).then(data => {
					var itemList = data.items;
					for (let i in itemList) {
						num = num + itemList[i].data.length;
					};
					that.cartNum = num;
				}).catch(() => {

				})
			},
			//商品分类
			getCategory() {
				var that = this;
				var param = {
					num: 100000,
					shop_id: 1
				};
				product.category(param).then(data => {
					that.categoryData = that.categoryData.concat(data.items);
					// that.navList[1].child = that.categoryData;
					that.cateList.child = that.categoryData;
					// that.oldNavList = that.categoryData;
					console.log(that.navList);
				}).catch(() => {

				});
			},
			toTxt(type) {
				this.$router.push({
					path: '/txt',
					query: {
						type: type
					}
				}).catch(() => {

				});
			},
			//
			toAbout(id) {
				this.$router.push({
					path: '/about',
					query: {
						type: id
					}
				}).catch(() => {

				});
			},
			toKefu() {
				this.$router.push({
					path: '/myorder',
					query: {
						index: '1-1'
					}
				}).catch(() => {

				});
			}
		}
	};
</script>
<style scoped>
	@import '../src/styles/ce-bian-custom.css';

	#app {
		font-size: 14px;
		background-color: #fff;
		color: #333;
	}

	.main-body {
		overflow-y: auto;
		overflow: hidden;
	}

	.none-dis {
		display: none !important;
	}

	.cursor {
		cursor: pointer;
	}

	.order-txt:hover,
	.register-txt:hover,
	.my-txt:hover,
	.cart-txt:hover,
	.my-txt:hover {
		color: #ff0000 !important;
		cursor: pointer;
	}

	.el-main {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
		padding: 0 !important
	}

	.el-card__body,
	.el-main {
		padding: 0 !important
	}

	a {
		color: #333;
	}

	.theme-color {
		color: #20b483;
	}

	.bg-fa {
		background-color: #fafafa;
	}

	.fs-12 {
		font-size: 12px;
	}

	.fs-14 {
		font-size: 14px;
	}

	.fs-16 {
		font-size: 16px;
	}

	.fs-18 {
		font-size: 18px;
	}

	.fs-32 {
		font-size: 32px;
	}

	h1 {
		font-size: 28px;
		text-align: center;
		width: 100%;
		padding: 20px 0;
	}

	h2 {
		font-size: 24px;
		text-align: center;
		width: 100%;
		padding: 20px 0;
	}

	h3 {
		font-size: 18px;
		text-align: center;
		width: 100%;
		padding: 20px 0;
	}

	.container,
	.container-fluid,
	.container-xxl,
	.container-xl,
	.container-lg,
	.container-md,
	.container-sm {
		margin-left: auto !important;
		margin-right: auto !important;
	}

	.el-header {
		background-color: #fff !important;
		color: #333;
		line-height: 60px;
		height: auto !important;
	}

	.top-login-box {
		height: auto !important;
		background-color: #f1f1f1 !important;
		padding: 0 10% !important;
		line-height: 50px !important;
		color: #666;
		position: fixed;
		top: 0;
		z-index: 999;
		width: 100%;
	}

	.header-left {
		display: flex;
		align-items: center;
		cursor: pointer;
	}

	.logo-img {
		width: 50px;
		margin-right: 10px;
	}

	.website-log-box {
		padding: 30px 0!important;

		&-item {
			display: flex;
			align-items: center;
		}
	}

	.website-log-box-two {
		padding: 20px !important;

		&-item {
			display: flex;
			align-items: center;
		}
	}

	.website-log-box-right {
		display: flex;
		align-items: center;
	}

	.website-name {
		font-size: 36px;
		color: #20b483;
		cursor: pointer;
	}

	.search-input {
		border-radius: 0;
		height: 50px !important;
		line-height: 50px !important;
		padding: 0 5px;
		border: 1px solid #eee;
		width: 100%;
		border-radius: unset
	}

	el-input,
	input {
		padding-right: 30px;
		line-height: 50px !important;
		height: 50px !important;
	}

	.el-button--success {
		color: #FFF;
		background-color: #20b483;
		border-color: #20b483;
		height: 50px;
		border-radius: 0;
	}

	.nav-list .el-menu--horizontal>.el-menu-item {
		cursor: pointer;
		font-size: 16px;
	}

	.nav-list {
		padding: 20px 0 !important;
	}

	.el-menu.el-menu--horizontal {
		display: flex;
	}

	.el-menu--horizontal>.el-menu-item.is-active {
		border-bottom: none !important;
		color: #20b483;
		padding: 0 40px;
		font-size: 16px;
	}

	.el-dropdown-link,
	.el-dropdown-link-item {
		font-size: 16px;
		cursor: pointer;
	}

	.el-dropdown-link a:hover {
		color: #20b483 !important;
	}

	.el-dropdown-link-active {
		color: #20b483 !important;
	}

	.pc-nav-bg .el-dropdown-link-active {
		color: #20b483 !important;
		/* background-color: #2a9765 !important; */
	}

	/* 轮播图 */
	.el-carousel__container {
		height: 400px !important;
	}

	.el-carousel__item el-image {
		color: #475669;
		font-size: 18px;
		opacity: 0.75;
		line-height: 400px;
		margin: 0;
	}

	.el-carousel__item:nth-child(2n) {
		background-color: #99a9bf;
	}

	.el-carousel__item:nth-child(2n+1) {
		background-color: #d3dce6;
	}

	.demo-image__lazy {
		height: 400px;
		overflow-y: auto;
	}

	.demo-image__lazy {
		height: 400px;
		overflow-y: auto;
	}

	.demo-image__lazy .el-image {
		display: block;
		min-height: 200px;
		margin-bottom: 10px;
		width: 100%;
	}

	.el-image {
		position: relative;
		display: inline-block;
		overflow: hidden;
		height: 100%;
		width: 100%;
	}

	/* 轮播图end */
	/* 精选商品 */
	p {
		color: #666;
		text-align: center;
		font-size: 16px;
	}

	.jingxuan {
		margin: 10px 0;
		border: 1px solid #eee;
		padding: 15px;
	}

	.jingxuan .el-image {
		padding-right: 30px;
	}

	.jx-product-namem {
		font-size: 18px;
		margin-bottom: 20px;
	}

	.pro-desc {
		color: #666;
	}

	.h1,
	.h2,
	.h3,
	.h4,
	.h5,
	.h6,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-family: inherit;
		font-weight: 500;
		line-height: 1.1;
		color: inherit;
	}

	.product-bottom-price-box {
		position: absolute;
		bottom: 0;
	}

	.product-price-box {
		margin-top: 20px;
		color: #20b483;
		font-size: 22px;
	}

	.jingxua .el-button--success {
		height: 40px;
		border-radius: 4px;
		margin: 20px 0;
	}

	/* end */
	/* 更多商品 */
	.item-box {
		text-align: center;
		margin-bottom: 5rem;
		border: 1px solid #eee;
		height: 400px;
		background-color: #fff;
		margin: 10px 0;
	}

	.item-box .tc img {
		height: 100% !important;
		width: 100% !important;
	}

	.item-box h3 {
		font-size: 14px;
		font-weight: 500;
		color: #333333;
		margin: 10px 0 !important;
		padding: 0 10px;
		line-height: 20px;
		text-overflow: -o-ellipsis-lastline;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		line-clamp: 2;
		-webkit-box-orient: vertical;
	}

	.item-box .width-100 {
		padding: 0 10px;
	}

	.pro-txt {
		text-align: left;
	}

	a {
		text-decoration: none !important;
	}

	.cart-img {
		width: 25px;
		height: 25px;
	}

	.text-left {
		display: flex;
		justify-content: space-between;
	}

	/* end */
	/* 底部 */
	.el-footer {
		height: auto !important;
		background-color: #2d2d2d;
		padding: 0 !important;
	}

	.assure-div {
		background-color: #20b483 !important;
		color: #fff;
	}

	.assure-box {
		display: none;
		align-items: center;
		padding: 40px 0;
		color: #fff;
	}

	.assure-item {
		display: flex;
		align-items: center;
	}

	.assure-box .assure-title {
		font-size: 18px;
	}

	.assure-box .assure-dec {
		color: #fff;
	}

	.assure-box .assure-item .iconfont {
		font-size: 68px;
		margin-right: 10px;
	}

	.box {
		padding: 80px 0 40px;
		background-color: unset !important;
	}

	ol,
	ul {
		padding-left: 0;
	}

	li {
		list-style: none;
	}

	footer .tit {
		font-size: 18px;
		/* color: #333; */
		color: #b8b3b3;
		line-height: 38px;
		margin-bottom: 20px;
		text-align: left;
	}

	footer a {
		font-size: 14px;
		line-height: 28px;
		color: #b8b3b3 !important;
	}

	.saoma {
		font-size: 12px;
		text-align: left;
	}

	/* end */
	.el-aside {
		color: #333;
	}

	.el-dropdown-link {
		text-align: center !important;
	}

	.pc-nav-bg {
		/* background-color: #20b483 !important; */
		background-color: #fff;
	}

	.pc-nav-bg .bg-purple a {
		display: block;
		color: #333;
		width: 100%;
	}

	.pc-nav-bg .pc-nav-item {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}

	.pc-nav-bg .pc-all-cate-title {
		display: flex;
		/* justify-content: space-between; */
		justify-content: space-around;
		align-items: center;
		padding: 0px 15px;
	}

	.pc-nav-bg .pc-all-cate-title .iconfont {
		font-size: 22px;
		color: #333;
	}

	.pc-parent-nav {
		position: relative !important;
		/* background-color: #20b483 !important;
		color: #fff; */
		background-color: #fff !important;
		color: #20b483;
		text-align: center;
		padding: 0 !important;
		cursor: pointer;
	}

	.pc-parent-nav .el-dropdown {
		width: 100%;
	}

	/* 鼠标经过父级分类展示子级分类 */
	.pc-parent-nav:hover .pc-child-nav {
		display: block;
	}

	.pc-child-nav {
		position: absolute !important;
		display: none;
		margin: 0 !important;
		padding: 10px 0 !important;
		border-radius: 0 !important;
		border: 1px solid #f5f5f5 !important;
		box-shadow: unset !important;
		width: 100%;
		height: 500px;
		top: 60px !important;
		background-color: #fff;
		z-index: 999;
		overflow: auto;
		overflow-x: hidden;
	}

	/* 自定义滚动条样式 */
	.pc-child-nav::-webkit-scrollbar {
		width: 8px;
		height: 8px;
	}

	::-webkit-scrollbar-button {
		display: none;
	}

	::-webkit-scrollbar-track {
		background-color: rgba(192, 194, 186, 0.4);
		display: none;
	}

	::-webkit-scrollbar-thumb {
		background-color: rgba(192, 194, 186, 0.6);
		border: 2px solid transparent;
		border-radius: 6px;
		background-clip: padding-box;
	}

	::-webkit-scrollbar-thumb:hover {
		background-color: rgba(0, 0, 0, 0.5);
	}

	/* 自定义滚动条样式end */
	.pc-child-nav .el-dropdown-link-item div {
		color: #333;
		padding: 5px 0 !important;
		line-height: 20px;
	}

	.pc-parent-nav .el-dropdown-link {
		padding-left: 5px;
		padding-right: 5px;

	}

	.pc-parent-nav .el-dropdown-link {
		color: #333 !important;
		/* color: #20b483 !important; */
	}

	.pc-child-nav .el-dropdown-link-item {
		font-size: 16px;
		cursor: pointer;
		text-align: left;
		padding: 5px 20px;
	}

	.el-popper[x-placement^=bottom] {
		margin-top: 0 !important;
	}

	.pc-child-nav-son span {
		color: #999;
		font-size: 14px;
		padding: 2px 8px 2px 0;
	}

	.pc-child-nav .el-dropdown-link-item div:hover,
	.pc-child-nav .pc-child-nav-son span:hover {
		color: #20b483;
	}

	.footer-ul li div,.footer-ul li {
		cursor: pointer;
		line-height: 28px;
		/* color: #333; */
		color: #b8b3b3;
		text-align: left;
	}

	/* 响应式 */
	/* 小屏幕（平板，大于等于 768px） */
	@media screen and (min-width: 768px) {
		/* 你的样式 */

	}

	@media only screen and (max-width: 767px) {
		.el-footer {
			padding: 0 20px !important;
		}

		.website-name {
			font-size: 20px;
		}

		.nav-list-box {
			display: flex;
			flex-wrap: wrap;
		}

		.el-dropdown-link {
			text-align: left;
		}

		.product-bottom-price-box {
			position: relative;

		}


		.item-box {
			height: 280px;
		}

		.item-box h3 {
			height: 40px;
		}

		.assure-box {
			display: block;
			padding: 20px 0 !important;
		}

		.assure-item {
			padding: 10px 0;
		}

		.assure-box .assure-item img {
			width: 40px !important;
			height: 40px !important;
			margin-right: 10px;
		}

		.assure-box .assure-title {
			font-size: 14px !important;
		}

		.assure-box .assure-dec {
			font-size: 12px !important;
		}

		footer .tit {
			font-size: 16px;
			margin: 10px 0;
			line-height: 20px;
			text-align: left;
		}

		footer ul {
			text-align: left;
		}

		footer a {
			font-size: 12px;
		}

		footer .box {
			padding: 20px 0;
			background-color: unset !important; 
		}

		.order-txt {
			text-align: left;
		}

		.cart-txt,
		.register-txt {
			text-align: center;
		}

		.my-txt {
			text-align: right;
		}

		.top-login-box {
			padding: 0 !important;
		}

		.el-dropdown {
			width: 100%;
			text-align: left;
		}

		.el-carousel__container {
			height: 200px;
		}

		.mune-icon {
			text-align: right;
		}

		.mune-icon .icon-liebiao {
			font-size: 36px;
			color: #666;
		}

		.app-logo-img {
			width: 60px;
			margin-right: 10px;
		}

		.website-log-box {
			padding: 10px 0 !important;
		}

		.app-nav {
			padding: 0 !important;
		}

		.nav-bottom {
			border-bottom: 1px solid #ddd;
			padding: 10px;
		}

		.app-nav .el-dropdown-link {
			line-height: 50px !important;
			font-size: 14px;
		}

		.website-name {
			font-size: 18px;
			color: #20b483;
		}

		.el-dropdown-menu {
			display: block !important;
			right: 0 !important;
			width: 96% !important;
			margin: auto !important;
			top: 50px;
		}

		/* //手机端产品分类 */
		.has-child {
			height: 250px !important;
		}

		.el-dropdown-link,
		.el-dropdown-link-item {
			font-size: 14px !important;
		}
	}

	/* end */
</style>