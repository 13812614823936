import { Config } from '../config.js';
class Base {
  constructor() {
    "use strict";
    this.baseRestUrl = Config;;
    this.tokenUrl = this.baseRestUrl + 'xcx-member/xcx-login';
  }
  request(params) {
    var that = this,
      url = this.baseRestUrl + params.url;
    if (!params.type) {
      params.type = 'POST';
    }
    var token = wx.getStorageSync('token'); //本地取存储的token
    var header = {
      'content-type': 'application/json'
    };

    if (token) {
      params.data.token = token
    }

    wx.request({
      url: url,
      data: params.data,
      method: params.type,
      header: header,
      success: function (res) {
        if (res.data.errcode == '401') {
          that.getTokenFromServer();
          if (params.url == "order/all?page=1&expand=orderSku,shop,data") {
            params.sCallback && params.sCallback(res.data);
          }
        } else if (res.data.errcode == '500') {
          wx.showToast({
            title: '服务器异常，请稍后重试',
            icon: 'none',
            duration: 3000
          })
        } else if (res.data.errcode == '400') {
          wx.showToast({
            title: res.data.errmsg ? res.data.errmsg : '没有权限',
            icon: 'none',
            duration: 3000
          })
        } else {
          params.sCallback && params.sCallback(res.data);
        }
      },
      fail: function () {
        wx.hideLoading();
        wx.showToast({
          title: '网络异常，请重试',
          icon: 'none',
          duration: 3000
        })
      },
      complete: function (res) {
        params.scomplete && params.scomplete(res.data);
      }
    });
  }

  getTokenFromServer() {
    var that = this;
    var share_mid = wx.getStorageSync('share_mid');
    wx.login({
      success: function (res) {
        if (res.code) {
          if (wx.getStorageSync('loging')) {
            return false;
          }
          var params = {
            code: res.code
          }
          if (share_mid) {
            params.pid = share_mid;
          }
          wx.setStorageSync('loging', true);
          wx.request({
            url: that.tokenUrl,
            method: 'POST',
            data: params,
            success: function (res) {

              if (res.data.user.status == 0) {
                wx.redirectTo({
                  url: '/pages/home/forbidden',
                })
                return false;
              }
              wx.setStorageSync('token', res.data.token);
              wx.setStorageSync('uid', res.data.user.id);
              wx.setStorageSync('userInfo', res.data.user);
            },
            complete: function (res) {
              wx.setStorageSync('loging', false);
            }
          })
        } else {
          console.log('登录失败！' + res.errMsg)
        }

      },
      fail: function () {
        wx.hideLoading();
        wx.showToast({
          title: '网络异常，请重试',
          icon: 'none',
          duration: 3000
        })
      }
    })
    wx.getSetting({
      success: function (res) {
        if (res.authSetting['scope.userInfo']) {
          // 已经授权，可以直接调用 getUserInfo 获取头像昵称
          wx.getUserInfo({
            success: function (res) {
              wx.setStorageSync('userInfo', res.userInfo);
              // 由于 getUserInfo 是网络请求，可能会在 Page.onLoad 之后才返回
              // 所以此处加入 callback 以防止这种情况
              // if (this.userInfoReadyCallback) {
              //   this.userInfoReadyCallback(res)
              // }
            }
          })
        }
      }
    })

  }
  /*获得元素上的绑定的值*/
  getDataSet(event, key) {
    return event.currentTarget.dataset[key];
  };

};

export { Base };