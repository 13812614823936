<template>
	<div v-loading='loading'>
		<!-- 轮播图 -->
		<el-row class="">
			<el-carousel :interval="50000" arrow="always" class="" :height=" bannerHeight + 'px' ">
				<el-carousel-item v-for="(item, index) in urls" :key="index" class="carousel-banner-item">
					<el-image :src="item.image"></el-image>
				</el-carousel-item>
			</el-carousel>
		</el-row>
		<!-- 轮播图end -->
		<!-- 顶部公告 -->
		<div class="container news-box">
			<el-row :gutter="10">
				<el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
					<div class="align-items">
						<div class="iconfont icon-gonggao gonggao"></div>
						<ul>
							<li class="new-item" v-for="(item,index) in news">
								<router-link :to="`/newsdetail?id=${item.id}&name=${item.title}`">
									{{item.title}}
								</router-link>
							</li>
						</ul>
					</div>
				</el-col>
				<el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
					<div class="">
						<input class="search-input" v-model="searchTxt" placeholder="请输入商品名称..." clearable />
						<el-button class="search-btn" @click="search" type="success" icon="el-icon-search">搜索</el-button>
					</div>
					<ul class="cate-list">
						<li class="cate-item" v-for="(item,index) in cateList">
							<router-link :to="`/allproduct?id=${item.category_id}&name=${item.cat_name}&index=index`">
								{{item.cat_name}}
							</router-link>
						</li>
					</ul>
				</el-col>
			</el-row>
		</div>
		<!-- 顶部公告end -->
		<!-- 顶部文章详情介绍 -->
		<div class="container pro-desc" v-if="intro_news">
			<el-row :gutter="10">
				<el-col :xs="24" :sm="24" :md="5" :lg="5" :xl="5">
					<div class="theme-color fs-32">{{intro_news.title}}</div>
					<div class="fs-18 cr-999">{{intro_news.english_title || ''}}</div>
				</el-col>
				<el-col :xs="24" :sm="24" :md="19" :lg="19" :xl="19">
					<div class="zizhi" v-if="intro_news.content" v-html="intro_news.content"></div>
				</el-col>
			</el-row>
		</div>
		<!-- 顶部文章详情介绍 end -->
		<!-- 背景+三个列表图 -->
		<div class="pro-desc-2" v-if="theme.length>0">
			<img class="background-image" v-if="theme.length>0&&theme[0].bgim.length>0" :src="theme[0].bgim[0].url" />
			<div :gutter="10" class="pro-2-list" v-if="theme[0].carousels.length>0">
				<div :xs="24" :sm="24" :md="4" :lg="4" :xl="4" v-for="(item,index) in theme[0].carousels">
					<img :src="item.url" />
				</div>
			</div>
		</div>
		<!-- 背景+三个列表图end -->
		<!-- 底部产品特点 -->
		<div class="container pro-desc-3" v-if="productIntroduct">
			<el-row :gutter="10">
				<el-col class="text-center pro-title-lable">
					<div class="theme-color fs-28">{{productIntroduct.title || ''}}</div>
					<div class="cr-999">{{productIntroduct.english_title || ''}}</div>
				</el-col>
			</el-row>
			<el-row :gutter="10">
				<div class="pro-img" v-if="productIntroduct.data&&productIntroduct.data.content" 
					v-html="productIntroduct.data.content"></div>
			</el-row>
		</div>
		<!-- 底部产品特点 end -->
		<!-- 商品分类 -->
		<div class="bg-fa pc-nav-list container">
			<el-row :gutter="10" class="">
				<el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="8" v-for="(item,index) in cateList">
					<div class="nav-item" @click.stop="toCate(item,index)">
		
						<img :src='item.image' v-if="item.image" />
						<div class="iconfont icon-nopicture" v-else />
						<div>
							<div class="nav-lable">{{item.cat_name}}</div>
							<div class="nav-lable-des">{{item.cat_name_des || ''}}</div>
						</div>
					</div>
				</el-col>
			</el-row>
		</div>
		<!-- 商品分类end -->
	</div>
</template>

<script>
	import {Adv} from '@/api/adv';
	var adv = new Adv();
	import {Product} from '@/api/product';
	var product = new Product();
	import { News } from '@/api/news';
	var news = new News();
	export default {
		data() {
			return {
				loading: false,
				urls: [],
				bannerHeight: 500, //图片父容器的高度
				screenWidth: 0, //屏幕的宽度
				childBannerHeight: 340,
				news:[],
				cateList:[],
				intro_news:{
					title:"",
					english_title:'',
					content:''
				},
				productIntroduct:{},
				theme:[],
				searchTxt:''
			}
		},
		created() {
			this.loading = true;
			this.getBanner();
			this.getCate();
			this.getNewList();
			this.getIntro();
			this.getThemeList();
			this.getProductIntro();
		},
		mounted: function() {
			this.screenWidth = window.innerWidth;
			this.setSize();
			window.onresize = () => {
				this.screenWidth = window.innerWidth;
				this.setSize();
			};
		},
		methods: {
			setSize: function() {
				// 通过屏幕宽度(图片宽度)计算高度
				this.bannerHeight = 500 / 1920 * this.screenWidth;
				this.childBannerHeight = 340 / 1920 * this.screenWidth;
			},
			//搜索
			search() {
				this.$router.push({
					path: '/allproduct',
					query: {
						input: this.searchTxt
					}
				}).catch(() => {
			
				});
			},
			//广告
			getBanner() {
				var that = this;
				var param = {
					'num': 8,
					'key': 'pc_banner'
				};
				adv.list(param).then(data => {
					that.urls = data.items;
					that.loading = false;
				}).catch(() => {
					that.loading = false;
				});
			},
			getCate() {
				var that = this;
				var param = {
					num: 100000,
					shop_id: 1
				};
				product.category(param).then(data => {
					that.cateList = data.items;
				}).catch(() => {
			
				});
			},
			getNewList() {
				var that = this;
				var param = {
					num:3
				};
				news.newsList(param).then(data => {
					that.news = data.items;
				}).catch(() => {
			
				});
			},
			//顶部文章详情介绍
			getIntro() {
				var that = this;
				var param = {
					num:1,
					is_top:1
				};
				news.newsList(param).then(data => {
					if(data.items.length>0){
						var c_res={id:data.items[0].id};
						news.detail(c_res).then(res => {
						    that.intro_news.title = res.title;
							that.intro_news.content = res.data.content;
							that.intro_news.english_title=res.english_title;
						}).catch(() => {
						    
						})
					}
				}).catch(() => {
			
				});
			},
			//主题
			getThemeList(){
				var that = this;
				var param = {};
				product.themeList(param).then(data => {
					that.theme=data.items;
				}).catch(() => {
					
				});
			},
			//底部产品介绍
			getProductIntro() {
				var that = this;
				var param = {
					num:1,
					is_best:1
				};
				news.newsList(param).then(data => {
					if(data.items.length>0){
						var c_res={id:data.items[0].id};
						news.detail(c_res).then(res => {
						that.productIntroduct=res
						}).catch(() => {
						    
						})
					}
				}).catch(() => {
			
				});
			}
		},
	}
</script>

<style scoped>
	#app {
		font-size: 16px;
		background-color: #fff;
		color: #333;
	}
	.news-box{
		    padding: 40px !important;
		    box-shadow: 0 -2px 12px rgba(0, 0, 0, 0.2);
		    margin: -40px auto 40px;
		    z-index: 8;
		    display: block;
		    position: relative;
		    background-color: #fff;
		    border-radius: 8px;
	}
	.align-items{
		display: flex;
		align-items: center;
	}
	.gonggao{
		font-size: 48px !important;
		color: #20b483;
		width: 100px;
	}
	.news-box .new-item{
		line-height:28px;
		color: #666;
		list-style: disc;
		margin-left: 20px;
	}
	.news-box .new-item a{
		color: #666 !important;
	}
	.search-input {
		border-radius: 0;
		height: 50px !important;
		line-height: 50px !important;
		padding: 0 5px;
		border: 1px solid #eee;
		width: calc(100% - 100px);
		border-radius: unset
	}
	.search-btn{
		width: 100px;
	}
	input:focus,input::after{
		border: 1px solid #eee !important;
	}
	.cate-list{
		display: flex;
		flex-wrap: wrap;
	}
	.cate-item{
		list-style: none !important;
		margin: 8px 4px;
		margin-left: 0 !important;
		color: #999 !important;
		line-height: 1.2 !important;
		a{
			color: #999 !important;
		}
	}
	li{
		cursor: pointer;
	}
	.fs-32{
		font-size: 32px;
	}
	.fs-18{
		font-size: 18px;
	}
	.fs-28{
		font-size: 28px;
	}
	.cr-888{
		color: #b8b8b8;
	}
	.font-weight{
		font-weight: bold;
		padding: 0 !important;
	}
	::v-deep p img,::v-deep p video{
		width: 200px;
		height: 200px;margin: 5px 0 !important;
	}
	.zizhi ::v-deep p{
		display: flex;
		flex-wrap: wrap;
	}
	.pro-img ::v-deep p{
		display: flex;
	}
	.pro-desc,.pro-desc-3{
		margin: 40px auto;
	}
	::v-deep .pro-desc-3 p img,::v-deep .pro-desc-3 p video{
		width: auto;
		min-width: 23%;
		height: 300px;margin: 10px 0.5% !important;
	}
	.pro-desc-2{
		/* background-image: url(../assets/bg.jpg); */
		background-repeat: no-repeat;
		background-size: 100%;
		overflow: hidden; /* 确保图片不会溢出容器 */
		height: 400px;
		width: 100%;
		 position: relative;
		 margin-bottom: 40px;
	}
	/* 伪元素用于设置透明背景 */
	.pro-desc-2::before {
	  content: '';
	  display: block;
	  position: absolute;
	  top: 0;
	  left: 0;
	  right: 0;
	  bottom: 0;
	  background: rgba(0, 0, 0, 0.2); /* 设置背景颜色和透明度 */
	  z-index: 2;
	}
	 
	/* 背景图片 */
	.pro-desc-2 .background-image {
	  position: absolute;
	  top: 0;
	  left: 0;
	  width: 100%;
	  height: 100%;
	  z-index: 1; /* 确保图片在透明层之下 */
	}
	.pro-2-list{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 99;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.pro-2-list img{
		width: 180px;
		height: 300px;
		margin: 0 30px;
	}
	.pro-desc-3 img{height: 300px;margin: 10px 0;}
	.text-center{
		text-align: center;
	}
	.pro-title-lable{
		margin: 30px 0;
	}
	/* 导航nav */
	.pc-nav-list {
		padding: 40px 10%;
	}
	
	.nav-item {
		cursor: pointer;
		display: flex;
		align-items: center;
		background-color: #fff;
		margin-bottom: 20px;
		padding: 20px 10px;
	}
	
	.nav-item img {
		width: 70px;
		height: 70px;
		margin-right: 10px;
	}
	
	.nav-item .iconfont {
		font-size: 68px;
		color: #20b483;
	}
	
	.nav-lable {
		/* font-weight: bold; */
	}
	.nav-lable-des {
		font-size: 12px;
	}
	.nav-english {
		font-size: 12px;
		color: #999;
	}
	
	/* 导航nav end*/
	@media only screen and (max-width: 767px) {
		.pro-desc-2{
			height: 200px !important;
		}
		.pro-desc-3 img{
		    height: 100px;
		    margin: 10px 0;
		}
		.pro-2-list img {
		    width: 90px;
		    height: 150px;
		    margin: 0 10px;
		}
		::v-deep .pro-desc-3 p img,::v-deep .pro-desc-3 p video{
			height: 100px;margin: 10px 0.5% !important;
		}
		.nav-item{
			height: 110px;
		}
	}
</style>