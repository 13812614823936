import request from '@/utils/request'
class Product {
	//获取主题列表
	themeList(param,callback){
		return request({
		  url: '/product-theme/index',
		  method: 'get',
		  data: param,
		  headers: {
		    'Content-Type': 'application/json;charset=UTF-8'
		  }
		});
	}
  //商品列表
  productList(params) {
    return request({
      url: '/product/index',
      method: 'get',
      data: params,
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      }
    })
  }
  //商品详情
  productDetail(params) {
    return request({
      url: '/product/detail',
      method: 'post',
      data: params,
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      }
    })
  }
  //获取某个商品评论列表
  getByGoods(params) {
    return request({
      url: 'product/comment?expand=member,replys&per-page=' + params.per_page,
      method: 'post',
      data: params,
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      }
    })
  }
  //商品类目
	category(params) {
    return request({
      url: '/product-category/index?num=' + params.num,
      method: 'post',
      data: params,
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      }
    })
	}
  /*切换商品属性值*/
  changAttr(selectIndex,attrIndex, productDetail) {
    var selectIndex = selectIndex; //行（选择的）
    var attrIndex = attrIndex; //列（选择的）
    var attributes = productDetail.attributes; //规格，child为规格值
    var skus = productDetail.skus; //SKu
    var count = attributes[selectIndex].child.length; //当前选中的规格的规格值总数
    if (attributes[selectIndex].child[attrIndex].is_select == 0) { //如果当前点中的规格值不是选中状态，设置为选中，并且同级规格值设置为不选中
      for (var i = 0; i < count; i++) {
        attributes[selectIndex].child[i].is_select = 0;
      }
      attributes[selectIndex].child[attrIndex].is_select = 1; //设置为选中
    } else {
      attributes[selectIndex].child[attrIndex].is_select = 0; //如果当前点中的规格值不是选中状态，设置为选中
    }
    var appearanceAttrValues = new Array();
    var skuStockArray = new Array(); //有库存的sku，二维数组[arrIndex][attrvalue1,attrvalue2,....]
    var noStockSku = new Array(); //没有库存的sku，二维数组[arrIndex][attrvalue1,attrvalue2,....]
    for (var i = 0; i < skus.length; i++) {
      if (skus[i].stock > 0) {
        var skuId = skus[i].sku_id;
        var skuValueArray = skuId.slice(skuId.indexOf('_') + 1).split("_");
        skuStockArray.push(skuValueArray);
        for (var j = 0; j < skuValueArray.length; j++) {
          if (appearanceAttrValues.indexOf(skuValueArray[j]) < 0) {
            appearanceAttrValues.push(skuValueArray[j]);
          }
        }
      } else {
        var skuId = skus[i].sku_id;
        var skuValueArray = skuId.slice(skuId.indexOf('_') + 1).split("_");
        noStockSku.push(skuValueArray);
      }
    }
    var selectedChild = new Array(); //select的valueId的二维数组。即每一行选中的value值数组:[row][value_id]
    var emptyStock = new Array();
    var attrValues = new Array(); //所有的attr的value二维数组[row][attrvalue1,attrvalue2....]
    for (var i = 0; i < attributes.length; i++) {
      selectedChild[i] = new Array();
      attrValues[i] = new Array();
      emptyStock[i] = new Array();
      //初始化stock和  
      //设置selectedChild
      for (var m = 0; m < attributes[i].child.length; m++) {
        attributes[i].child[m].stock = 1;
        attrValues[i].push(attributes[i].child[m].value_id);
        if (attributes[i].child[m].is_select == 1) {
          selectedChild[i].push(attributes[i].child[m].value_id);
        }
      }
    }
    for (var i = 0; i < selectedChild.length; i++) {
      if (selectedChild[i].length <= 0) {
        //只有一种规格时
        if (skuStockArray[0].length == 1) {
          if (noStockSku && noStockSku[0]) {
            for (var jj = 0; jj < noStockSku.length; jj++) {
              var temp = new Array();
              temp[0] = noStockSku[jj];
              emptyStock[0].push(this.deepcopy(temp));
            }
          }
        }
        continue;
      }
      var copyAttrValues = this.deepcopy(attrValues) //即将填充到emptyStock的值，等同于emptyStock
      var selectedValue = selectedChild[i][0]; //第i行选中的值
      //遍历整个skustockArray
      for (var j = 0; j < skuStockArray.length; j++) {
        var skuStock = skuStockArray[j]; //有库存的sku数组的value值数组[attrvalue1,attrvalue2]，里面的value分别对应不同的attr
        if (skuStock.indexOf(selectedValue.toString()) > -1) { //找出所有包含该selectedValue的skuStock；
          for (var k = 0; k < skuStock.length; k++) {
            if (skuStock[k] == selectedValue) {
              //当只有一种规格时
              if (skuStock.length == 1 && noStockSku[k]) {
                for (var jj = 0; jj < noStockSku.length; jj++) {
                  copyAttrValues[jj] = noStockSku[jj];
                }
              } else {
                copyAttrValues[k] = new Array(); //设置同一行的所有的value值为空
              }
              continue;
            } else {
              var index = copyAttrValues[k].indexOf(parseInt(skuStock[k]));
              if (index > -1) {
                copyAttrValues[k].splice(index, 1);
              }
            }
          }
        }

      }
      emptyStock[i] = this.deepcopy(copyAttrValues);
    }
    var emptyStockValues = new Array();
    for (var i = 0; i < emptyStock.length; i++) {
      for (var j = 0; j < emptyStock[i].length; j++) {
        for (var k = 0; k < emptyStock[i][j].length; k++) {
          var emptyStockValue = emptyStock[i][j][k];
          if (emptyStockValues.indexOf(emptyStockValue) < 0) {
            emptyStockValues.push(emptyStockValue);
          }
        }
      }
    }

    for (var i = 0; i < attributes.length; i++) {
      if (emptyStockValues.length == 0) {
        for (var m = 0; m < attributes[i].child.length; m++) {
          attributes[i].child[m].stock = 1; //stock设置为0;            
        }
      } else {
        for (var j = 0; j < emptyStockValues.length; j++) {
          for (var m = 0; m < attributes[i].child.length; m++) {
            if (emptyStockValues[j] == attributes[i].child[m].value_id) {
              attributes[i].child[m].stock = 0; //stock设置为0;   
            }
          }
        }
      }

    }


    return attributes;
  }
  deepcopy(obj) {
		var out = [],
			i = 0,
			len = obj.length;
		for (; i < len; i++) {
			if (obj[i] instanceof Array) {
				out[i] = this.deepcopy(obj[i]);
			} else out[i] = obj[i];
		}
		return out;
	}
  /*调整商品价格图片库存等信息*/
	changProductinf(productDetail) {
		if (this.isSelectAll(productDetail).skustr) {
			var skustr = "";
			skustr = this.isSelectAll(productDetail).skustr;
			for (var i = 0; i < productDetail.skus.length; i++) {
				if (productDetail.skus[i].sku_id == skustr) {
					return i;
					break;
				}
			}
		}
	}

	isSelectAll(productDetail) {
		var attributes = productDetail.attributes;
		var skus = [],
			unselected = [];
		for (var i = 0; i < attributes.length; i++) {
			for (var j = 0; j < attributes[i].child.length; j++) {
				if (attributes[i].child[j].is_select == 1) {
					var skuobj = {};
					skuobj.value_id = attributes[i].child[j].value_id;
					skuobj.value_str = attributes[i].child[j].value_str;
					skus.push(skuobj);
				}
			}
			if (!skus[i]) {
				skus[i] = "unselected"; //没有选的属性组，标记为未选
				unselected.push(attributes[i].attribute_name);
			}
		}
		var unselectLabel = unselected.join(" ");
		if (!unselectLabel) {
			var skustr = productDetail.product.product_id;
			var selectLabel = '已选';
			for (var i = 0; i < skus.length; i++) {
				skustr += '_' + skus[i].value_id; //构造 12_34_56_78形式，第一段数字是商品id
				selectLabel += ' ' + skus[i].value_str;
			}
			return {
				selectLabel,
				skustr
			}
		} else {
			return {
				unselectLabel
			};
		}
	}

	/*调整商品数目*/
	changeCounts(type, productCounts, maxCounts) {
		var mark = type;
		currentNum = 1;
    console.log(type)
		if (mark == '1') {
      
			var currentNum = productCounts;
			currentNum++;
		} else if (mark == '0' && productCounts > 1) {
			var currentNum = productCounts;
			currentNum--;
		}
    console.log(currentNum)
		return currentNum;
	}
	getDataSet(event, key) {
		return event.currentTarget.dataset[key];
	}
	//获取商品分享二维码
	qrcode(param, callback) {
		var that = this;
		http.post('xcx-member/qrcode', param).then(data => {
			callback && callback(data);
		});
	}
	//搜索历史
	searchHistory(param, callback) {
		http.get('product/history', param).then(data => {
			callback && callback(data);
		});
	}
	//清空搜索记录
	del_history(param, callback) {
		var that = this;
		http.get('product/truncate', param).then(data => {
			callback && callback(data);
		});
	}
	//秒杀商品信息
	getPromProductData(params, callback) {
		http.get('promotion/flash-sale?expand=product,skus', params).then(data => {
			callback && callback(data);
		});
	}
};
export {
	Product
};


